import keys from 'lodash/keys';

type Message = string | NestedDictionary;
interface NestedDictionary {
  [x: string]: Message;
}
interface FlattenedDictionary {
  [x: string]: string;
}

export const flattenMessages = (
  nestedMessages: NestedDictionary,
  prefix = '',
): FlattenedDictionary =>
  keys(nestedMessages).reduce((messages: FlattenedDictionary, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    const messagesFlattend = { ...messages };
    if (typeof value === 'string') {
      messagesFlattend[prefixedKey] = value;
    } else {
      Object.assign(messagesFlattend, flattenMessages(value, prefixedKey));
    }
    return messagesFlattend;
  }, {});

export default flattenMessages;
