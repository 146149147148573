/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'superagent';
import { API_URL } from 'services/config';
import { getLanguage } from 'redux/Language';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

class Client {
  baseUrl: string;

  withCredentials: boolean;

  agent: request.SuperAgentStatic & request.Request;

  constructor(baseUrl: string, withCredentials: boolean) {
    this.baseUrl = baseUrl;
    this.withCredentials = withCredentials;
    this.agent = request.agent();
    this.agent.accept('application/json');
    if (withCredentials) {
      this.agent.withCredentials();
    }
  }

  async downloadFile(
    endpoint: string,
    fileName: string,
  ): Promise<{ status: number }> {
    return this.downloadFileAsBlob(endpoint).then((response) => {
      const { body: blob, status } = response;
      if (status === 200) {
        const url = window.URL.createObjectURL(blob as Blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      return Promise.resolve({ status });
    });
  }

  async downloadFileAsBlob(endpoint: string): Promise<any> {
    return this.request('get', endpoint, null, 'blob');
  }

  async request(
    method: Method,
    endpoint: string,
    data: any | null = null,
    responseType?: string,
  ) {
    const lang = getLanguage();

    let newEndpoint = endpoint;
    if (endpoint.includes('?')) {
      newEndpoint += `&lang=${lang}`;
    } else {
      newEndpoint += `?lang=${lang}`;
    }

    const url = /^https?:\/\//.test(newEndpoint)
      ? newEndpoint
      : `${this.baseUrl}${newEndpoint}`;
    let promise = this.agent[method](url);

    if (['post', 'put', 'patch'].includes(method) && data) {
      promise = promise.send(data);
    }

    if (responseType) promise = promise.responseType(responseType);

    return promise
      .ok((res) => res.status <= 500)
      .catch(() => {
        return { body: { message: '' }, status: 500 };
      });
  }

  async get(
    endpoint: string,
    formatter: (response: any) => any = (formatterResponse) =>
      formatterResponse,
  ) {
    const response = await this.request('get', endpoint);
    return formatter(response);
  }

  post(endpoint: string, data?: any) {
    return this.request('post', endpoint, data);
  }

  put(endpoint: string, data: any) {
    return this.request('put', endpoint, data);
  }

  async login(data: any) {
    return this.post('/auth/login', data);
  }

  async logout() {
    await this.post('/auth/logout');
  }
}

const client = new Client(API_URL, false);

export default client;
