import React from 'react';
import TitleWithCircle from 'components/TitleWithCircle';
import { FormattedMessage } from 'react-intl';
import Carousel from 'components/Carousel';
import { getVideoImage } from 'utils/Helper';
import {
  CarouselSliderBackground,
  CarouselSliderContainer,
  CarouselSliderMain,
  SliderSpacing,
  HorizontalDotsContainer,
} from './GenericCarouselSlider.style';
import Card from './Card';
import { VideoItem } from '../type';

export interface CarouselSliderProps {
  itemsList: VideoItem[];
  titleColor?: string;
  circleColor?: string;
  showVideo: (url: string) => void;
}

const GenericCarouselSlider: React.FC<CarouselSliderProps> = ({
  itemsList,
  titleColor,
  circleColor,
  showVideo,
}) => {
  return (
    <CarouselSliderContainer>
      <CarouselSliderBackground />
      <CarouselSliderMain align="center">
        <TitleWithCircle titleColor={titleColor} circleColor={circleColor}>
          <FormattedMessage id="videotheque.carousel-slider.title" />
        </TitleWithCircle>
        <SliderSpacing>
          <HorizontalDotsContainer />
        </SliderSpacing>
        <Carousel>
          {itemsList.map((item: VideoItem) => (
            <Card
              key={item.title}
              title={item.title}
              description={item.descriptionSlided}
              imgUrl={getVideoImage(item.videoLink)}
              alt={item.title}
              showVideo={() => showVideo(item.videoLink)}
              profilColor={titleColor}
            />
          ))}
        </Carousel>
      </CarouselSliderMain>
    </CarouselSliderContainer>
  );
};

export default GenericCarouselSlider;
