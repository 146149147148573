import styled from 'styled-components';
import {
  MOBILE_MAX_WIDTH,
  reactAdminRichTextStyle,
  TABLET_MAX_WIDTH,
} from 'stylesheet';

export const RichTextRendererContainer = styled.div`
  ${reactAdminRichTextStyle};
  width: 100%;
  white-space: pre-wrap;
  direction: ${(props) => props.theme.richTextRenderer.direction};
  line-height: ${(props) => props.theme.richTextRenderer.lineHeight};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
  }
`;
