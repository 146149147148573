import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImgPictoUrl from 'assets/png/ico-play.png';
import { Card } from '@material-ui/core';
import { setSelectedVideo as setFromRedux } from 'redux/Videotech/slice';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'components/Generic/Popup/Popup';
import { colorPalette } from 'stylesheet';
import {
  CardContainer,
  Description,
  ImgLink,
  MediaContainer,
  PictoImg,
  Title,
  VideoImg,
} from './VideoTile.style';

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    boxShadow: `0 0.56vw 1.66vw ${colorPalette.lightBlack}`,
    margin: 10,
    marginBottom: 25,
  },
});

interface DataProps {
  videoLink: string | undefined;
  alt: string;
  imgUrl: string;
  title: string;
  description: string;
}

const VideoTile: React.FC<{
  data?: DataProps;
  titleColor: string | undefined;
  onMoreDetail?: (url: string | undefined) => void;
}> = ({ data, titleColor }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const selectedVideoRedux = useSelector(
    (state) => state.videoTech.selectedVideo,
  );

  const dispatch = useDispatch();

  const handleClickOpen = (url: string) => {
    dispatch(setFromRedux(url));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={classes.root}>
      <CardContainer>
        <ImgLink
          href="#"
          onClick={() => {
            handleClickOpen(data?.videoLink || '');
          }}
        >
          <MediaContainer>
            <VideoImg src={data?.imgUrl} alt={data?.alt} />

            <PictoImg src={ImgPictoUrl} alt={data?.alt} />
          </MediaContainer>
        </ImgLink>
        <Title href="#" color={titleColor}>
          {data?.title}
        </Title>
        <Description>{data?.description}</Description>
      </CardContainer>
      <Popup
        open={open}
        handleClose={handleClose}
        videoLink={selectedVideoRedux}
      />
    </Card>
  );
};

export default VideoTile;
