/* eslint-disable complexity */
import DescriptionByProfil from 'components/DescriptionByProfil';
import React from 'react';
import { useIntl } from 'react-intl';
import { DescriptionContainer } from 'pages/HomeByProfil/HomeByProfil.style';
import {
  PROFIL_HOME_PATHNAME_MAPPING,
  TITLE_STYLE_PROPS_BY_PROFIL,
} from 'pages/services';
import { DESCRIPTION_IMAGE_BY_PROFIL } from 'pages/HomeByProfil/services';
import { useLocation } from 'react-router';
import useSelector from 'redux/useSelector';

const Description: React.FC = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const description = useSelector(
    (state) =>
      (state.homeByProfile[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {})
        .description,
  );
  const { colorPrimary, colorSecondary } =
    TITLE_STYLE_PROPS_BY_PROFIL[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {};

  const imgUrl =
    DESCRIPTION_IMAGE_BY_PROFIL[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || '';

  return (
    <DescriptionContainer>
      <DescriptionByProfil
        title={intl.formatMessage({
          id: 'home.parent-tuteur.description.title',
        })}
        description={description || ''}
        svgImgUrl={imgUrl}
        colorPrimary={colorPrimary || ''}
        colorSecondary={colorSecondary || ''}
      />
    </DescriptionContainer>
  );
};

export default Description;
