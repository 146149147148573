import { range } from 'lodash';
import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * App spacing measurement convention
 * Use the calcViewPort function below to compute padding and margin
 */

const VIEW_WIDTH_PORT_UNIT = 'vw';
const VIEW_HEIGHT_PORT_UNIT = 'vh';
const MAQUETTE_PAGE_WIDTH = 1440;
const MAQUETTE_PAGE_HEIGHT = 1024;
export const calcViewWidthPort = (maquetteUIPixels: number): string =>
  `${(maquetteUIPixels / MAQUETTE_PAGE_WIDTH) * 100}${VIEW_WIDTH_PORT_UNIT}`;

export const calcViewHeightPort = (maquetteUIPixels: number): string =>
  `${(maquetteUIPixels / MAQUETTE_PAGE_HEIGHT) * 100}${VIEW_HEIGHT_PORT_UNIT}`;

export const BETWEEN_DESKTOP_TABLET_WIDTH = '912px';
export const TABLET_MAX_WIDTH = '769px';
export const TABLET_MIN_WIDTH = '767px';
export const BETWEEN_TABLET_MOBILE_WIDTH = '600px';
export const MOBILE_MAX_WIDTH = '426px';
export const SMALL_MOBILE_MAX_WIDTH = '320px';
export const MEDIUM_MOBILE_MAX_WIDTH = '375px';

export const colorPalette = {
  black: '#000000',
  lightBlack: '#00000026',
  white: '#FFFFFF',
  jacksonsPurple: '#1d3798',
  aquamarine: '#83CCDD',
  opacAquamarine: '#83CCDD80',
  outerSpace: '#31373E',
  falseIndigo: '#4A69BC',
  orange: '#F5B73C',
  darkOrange: '#FC961D',
  lightGreen: '#78DF8E',
  cobaltBlue: '#1B3DC6',
  greyLight: '#737171',
  cyan4: '#099A93',
  cyan5: '#38ACA9',
  lightCyan: '#e6f5f8',
  hawkesBlue: '#CADFFF',
  darkWashedBlue: '#304FFE',
  plum: '#B9E993',
  pink: '#78DF8E',
  darkBlue: '#0E2460',
  whiteGrey: '#faf9f7',
  lightBlueGrey: '#cfd0db',
  whityBlue: '#E6F5F8',
  red: '#f44336',
};

export const fontFamily = {
  main: 'kiro',
  pattaya: 'pattaya',
  arabic_bold: 'cairoBold',
  arabic: 'cairo',
};

export type FontType =
  | 'xxlarge'
  | 'xlarge'
  | 'large'
  | 'largeTablet'
  | 'largeMobile'
  | 'xmedium'
  | 'xmediumTablet'
  | 'xmediumMobile'
  | 'medium'
  | 'mediumTablet'
  | 'mediumMobile'
  | 'smedium'
  | 'small'
  | 'smallTablet'
  | 'smallMobile'
  | 'xsmall';

export type ITypography = {
  [key in FontType]: FlattenSimpleInterpolation;
};

export const typography: ITypography = {
  xxlarge: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(40)};
    line-height: ${calcViewWidthPort(48)};
  `,
  xlarge: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(30)};
    line-height: ${calcViewWidthPort(36)};
  `,
  large: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(24)};
    line-height: ${calcViewWidthPort(29)};
  `,
  largeTablet: css`
    font-weight: bold;
    font-size: 20px;
    line-height: normal;
  `,
  largeMobile: css`
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
  `,
  xmedium: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(20)};
    line-height: ${calcViewWidthPort(24)};
  `,
  xmediumTablet: css`
    font-weight: bold;
    font-size: 14px;
  `,
  xmediumMobile: css`
    font-weight: bold;
    font-size: 14px;
  `,
  medium: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(16)};
    line-height: ${calcViewWidthPort(19)};
  `,
  mediumTablet: css`
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
  `,
  mediumMobile: css`
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
  `,
  smedium: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(14)};
    line-height: ${calcViewWidthPort(20)};
  `,
  small: css`
    font-weight: normal;
    font-size: ${calcViewWidthPort(16)};
    line-height: ${calcViewWidthPort(19)};
  `,
  smallTablet: css`
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
  `,
  smallMobile: css`
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
  `,
  xsmall: css`
    font-weight: bold;
    font-size: ${calcViewWidthPort(12)};
    line-height: ${calcViewWidthPort(14)};
  `,
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const boxShadows = {
  default: `0 ${calcViewWidthPort(8)} ${calcViewWidthPort(
    24,
  )} rgba(0,0,0,0.15)`,
  hover: `0 ${calcViewWidthPort(8)} ${calcViewWidthPort(24)} ${
    colorPalette.aquamarine
  }`,
};

const INDENT_MAX = 20;
let allIndentsStyle = '';
range(1, INDENT_MAX + 1).forEach((i) => {
  allIndentsStyle += `
    .ql-indent-${i} {
      margin-inline-start: ${calcViewWidthPort(i * 40)};
    }
  `;
});

export const reactAdminRichTextStyle = css`
  ${typography.xmedium};
  font-weight: normal;
  text-align: start;
  color: ${colorPalette.black};
  p {
    margin: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  ul {
    margin: ${calcViewWidthPort(16)};
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: ${calcViewWidthPort(40)};
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      padding-inline-start: 30px;
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding-inline-start: 24px;
    }
  }
  ol {
    margin: ${calcViewWidthPort(16)};
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: ${calcViewWidthPort(40)};
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      padding-inline-start: 30px;
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      padding-inline-start: 24px;
    }
  }
  li {
    display: list-item;
  }
  a {
    color: ${colorPalette.darkWashedBlue};
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  .ql-size-small {
    ${typography.xsmall}
  }
  .ql-size-large {
    ${typography.large}
  }
  span.ql-size-large,
  strong.ql-size-large {
    ${typography.large}
    @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      font-size: 14px;
      line-height: 18px;
      text-align: justify;
    }
  }
  .ql-size-huge {
    ${typography.xlarge}
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: ${(props) => props.theme.richTextRenderer.textAlign};
  }
  .ql-align-justify {
    text-align: justify;
  }
  ${allIndentsStyle};
`;
