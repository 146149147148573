import React, { useState, useEffect } from 'react';
import client from 'services/networking/client';

const StaticContentByKey = ({
  staticContentKey,
}: {
  staticContentKey: string;
}) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const result = await client.get(
          `/static-contents/light/${staticContentKey}`,
        );
        setIsLoading(false);
        if (result.body && result.status !== 500) {
          setContent(result.body.value);
        } else {
          setContent('An error occurred... ');
        }
      } catch (error) {
        console.error('Error fetching data from API:', error);
        setContent('An error occurred... ');
        setIsLoading(false);
      }
    };
    fetchContent();
  }, [staticContentKey]);

  return (
    <span>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </span>
  );
};

export default StaticContentByKey;
