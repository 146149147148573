import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { getLanguage } from 'redux/Language';
import {
  CarouselButton,
  PrevIconImg,
  NextIconImg,
  MUICarouselStyled,
} from './Carousel.style';

const StyledPaper = withStyles({
  root: {
    backgroundColor: 'transparent',
  },
})(Paper);

const Carousel: React.FC = ({ children }) => {
  const lang = getLanguage();
  return (
    <MUICarouselStyled
      lang={lang}
      indicators={false}
      animation="slide"
      timeout={400}
      NavButton={({ onClick, next, prev }) => (
        <CarouselButton onClick={onClick} $next={next} $prev={prev}>
          {Boolean(next) && <NextIconImg alt="bouton suivant slider" />}
          {Boolean(prev) && <PrevIconImg alt="bouton retour slider" />}
        </CarouselButton>
      )}
    >
      {React.Children.map(children, (child) => (
        <StyledPaper>{child}</StyledPaper>
      ))}
    </MUICarouselStyled>
  );
};
export default Carousel;
