import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LetterType =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | 'أ'
  | 'ب'
  | 'ت'
  | 'ث'
  | 'ج'
  | 'ح'
  | 'خ'
  | 'د'
  | 'ذ'
  | 'ر'
  | 'ز'
  | 'س'
  | 'ش'
  | 'ص'
  | 'ض'
  | 'ط'
  | 'ظ'
  | 'ع'
  | 'غ'
  | 'ف'
  | 'ق'
  | 'ك'
  | 'ل'
  | 'م'
  | 'ن'
  | 'ه'
  | 'و'
  | 'ي';

export type Definition = {
  word: string;
  definition: string;
};

export type IGlossary = {
  [key in LetterType]: Definition[];
};

export type GlossaryState = Readonly<{
  glossary: IGlossary | null;
}>;

export const initialState: GlossaryState = {
  glossary: null,
};

const glossarySlice = createSlice({
  name: 'glossary',
  initialState,
  reducers: {
    setGlossary: (state, action: PayloadAction<IGlossary>) => {
      const draftState = state;
      draftState.glossary = action.payload;
    },
  },
});
export const { setGlossary } = glossarySlice.actions;
export default glossarySlice.reducer;
