import styled from 'styled-components';
import { ReactComponent as CirclesGroupSvg } from 'assets/svg/circles-group-of-6-4.svg';
import { ReactComponent as CubicFourDots } from 'assets/svg/CubicFourDots.svg';
import { LanguageEnum } from 'redux/Language';
import {
  calcViewWidthPort,
  colorPalette,
  MEDIUM_MOBILE_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
  SMALL_MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from 'stylesheet';

interface LanguageProp {
  lang: LanguageEnum.ar | LanguageEnum.fr;
}

export const BackgroundContainer = styled.div`
  position: relative;
  margin-top: ${calcViewWidthPort(89)};
  overflow: hidden;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    min-width: 270px;
    margin-top: 68px;
  }
`;

export const StyledCubicFourDots = styled(CubicFourDots)`
  z-index: -1;
  position: absolute;
  top: 0;
  inset-inline-end: ${calcViewWidthPort(30)};
  width: ${calcViewWidthPort(140)};
  height: ${calcViewWidthPort(140)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    top: -26px;
    inset-inline-end: -35px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    inset-inline-end: ${calcViewWidthPort(20)};
    top: 15px;
  }
  @media screen and (max-width: ${MEDIUM_MOBILE_MAX_WIDTH}) {
    top: 5px;
  }
`;
export const PositionnedCirclesGroup = styled(CirclesGroupSvg)`
  z-index: -1;
  position: absolute;
  top: 0;
  inset-inline-end: ${calcViewWidthPort(163)};
  width: ${calcViewWidthPort(220)};
  height: ${calcViewWidthPort(140)};
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    top: -5px;
  }
  @media screen and (max-width: ${SMALL_MOBILE_MAX_WIDTH}) {
    top: 0;
  }
`;

export const StyledBackgroundLeftBigCircleSvg = styled.div<LanguageProp>`
  z-index: -1;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: ${calcViewWidthPort(748)};
  height: ${calcViewWidthPort(748)};
  border-radius: 50%;
  background-color: ${colorPalette.aquamarine};
  opacity: 0.2;
  transform: translateX(
    ${(props) => calcViewWidthPort(props.lang === LanguageEnum.ar ? 374 : -374)}
  );
`;

export const StyledBackgroundRightBigCircleSvg = styled.div<LanguageProp>`
  z-index: -1;
  position: absolute;
  top: ${calcViewWidthPort(370)};
  inset-inline-end: 0;
  width: ${calcViewWidthPort(500)};
  height: ${calcViewWidthPort(500)};
  border-radius: 50%;
  background-color: ${colorPalette.aquamarine};
  opacity: 0.2;
  transform: translateX(
    ${(props) => calcViewWidthPort(props.lang === LanguageEnum.ar ? -80 : 80)}
  );
`;
