import React, { useState, useEffect } from 'react';
import globalTheme from 'theme';
import { ThemeProvider as ThemeProviderContainer } from 'styled-components';
import useSelector from 'redux/useSelector';
import { LanguageEnum } from 'redux/Language';
import { getThemeByLang } from 'theme/service';

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    getThemeByLang(globalTheme, LanguageEnum.fr),
  );
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    if (language === LanguageEnum.ar) {
      setTheme(getThemeByLang(globalTheme, LanguageEnum.ar));
    } else {
      setTheme(getThemeByLang(globalTheme, LanguageEnum.fr));
    }
  }, [language]);

  return (
    <ThemeProviderContainer theme={theme}>{children}</ThemeProviderContainer>
  );
};
