import styled from 'styled-components';
import { colorPalette, calcViewWidthPort, typography } from 'stylesheet';
import { getColor } from './services';

const AquamarineButton = styled.button`
  ${typography.small};
  cursor: pointer;
  border-radius: ${calcViewWidthPort(30)};
  padding: ${calcViewWidthPort(15)};
  padding-inline-start: ${calcViewWidthPort(20)};
  padding-inline-end: ${calcViewWidthPort(20)};
  color: ${getColor(false)};
  background-color: ${getColor(true)};
  font-family: ${(props) => props.theme.actualityPage.fontFamily};
  font-weight: ${(props) => props.theme.actualityPage.fontWeight};
  border: ${calcViewWidthPort(1.5)} solid ${colorPalette.aquamarine};
  :hover,
  &.hovered {
    color: ${getColor(true)};
    background-color: ${getColor(false)};
  }
  @media screen and (max-width: 769px) {
    font-size: 13px;
    width: 80px;
    height: 28px;
  }
`;

export default AquamarineButton;
