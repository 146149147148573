import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { Persistor } from 'redux-persist';
import { Store } from 'redux';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Root from 'Root';
import { ThemeProvider, TranslationsProvider } from 'providers';
import { QueryClient, QueryClientProvider } from 'react-query';

interface Props {
  history: History;
  persistor: Persistor;
  store: Store;
}

const queryClient = new QueryClient();

const App: React.FC<Props> = ({ history, persistor, store }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TranslationsProvider>
          <ConnectedRouter history={history}>
            <ThemeProvider>
              <QueryClientProvider client={queryClient}>
                <Root />
              </QueryClientProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </TranslationsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
