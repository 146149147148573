import {
  persistStore,
  persistReducer,
  PERSIST,
  Persistor,
} from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { History } from 'history';
import { Store } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createReducer } from './reducers';

export function buildStore(
  history: History,
  preloadedState = {},
): { store: Store; persistor: Persistor } {
  const persistConfig = {
    key: 'root',
    whitelist: ['login'],
    storage,
  };
  const rootReducer = createReducer({ router: connectRouter(history) });
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [PERSIST],
        },
      }),
      routerMiddleware(history),
    ],
    reducer: persistedReducer,
    preloadedState,
  });

  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(() => store.replaceReducer(persistedReducer));
    });
  }

  return { store, persistor };
}
