const PATHS = {
  HOME: '/',
  HOME_BY_PROFIL: '/home',
  CHILDREN_HOME: '/enfants/home',
  PARENTS_HOME: '/parents/home',
  PARENTS_VIDEOTECH: '/parents/videotech',
  TEACHERS_HOME: '/enseignants/home',
  TEACHERS_VIDEOTECH: '/enseignants/videotech',
  YOUTH_HOME: '/jeunes/home',
  YOUTH_VIDEOTECH: '/jeunes/videotech',
  CHILDREN_VIDEOTECH: '/enfants/videotech',
  GUIDES: '/guides',
  CHILDREN_GUIDES: '/enfants/guides',
  CHILDREN_ADVICES: '/enfants/conseils',
  PARENTS_GUIDES: '/parents/guides',
  PARENTS_ADVICES: '/parents/conseils',
  PARENTS_ADVICE_DETAIL: '/parents/conseils/:slug',
  TEACHERS_ADVICE_DETAIL: '/enseignants/conseils/:slug',
  PARENTS_PARENTAL_CONTROL: '/parents/controle-parentale',
  PARENTS_PARENTAL_CONTROL_DETAILS: '/parents/controle-parentale/:slug',
  PARENTS_GUIDES_DETAILS: '/parents/guides/:slug',
  YOUTH_GUIDES_DETAILS: '/jeunes/guides/:slug',
  TEACHERS_GUIDES_DETAILS: '/enseignants/guides/:slug',
  TEACHERS_GUIDES: '/enseignants/guides',
  TEACHERS_ADVICES: '/enseignants/conseils',
  YOUTH_GUIDES: '/jeunes/guides',
  YOUTH_ADVICES: '/jeunes/conseils',
  YOUTH_ADVICE_DETAIL: '/jeunes/conseils/:slug',
  CHILDREN_MENU: '/enfants/menu',
  CHILDREN_QUIZ_MENU_INTRO: '/enfants/quizs/introduction',
  CHILDREN_QUIZ_MENU: '/enfants/quizs/menu',
  CHILDREN_QUIZ: '/enfants/quizs/:slug',
  ACTUALITIES: '/actualites',
  ACTUALITIES_DETAIL: '/actualites/:slug',
  LEGAL_NOTICE: '/mentions-legales',
  USE_CONDITIONS: '/conditions-d-utilisation',
  LEGAL_FRAMEWORK: '/cadre-juridique',
  CODE_PENAL: '/code-penal',
  INITIATIVES: '/initiatives',
  VIDEOS: '/videos',
  INFORMATIONS_UTILES: '/informations-utiles',
  INFORMATIONS_UTILES_PARTNERS: '/informations-utiles?bloc=partenaires',
  INITIATIVE_DETAIL: '/initiatives/:slug',
  PARTNERS: 'partenaires',
  GLOSSARY: '/glossaire',
  KIDS: '/enfants',
  ADMIN: '/admin',
  CHILDREN_LIBRARY: '/enfants/bibliotheque',
  CHILDREN_LIBRARY_EXPLAINED: '/enfants/bibliotheque/introduction',
  CHILDREN_LIBRARY_BOOK_CHOICE: '/enfants/bibliotheque/bouquin',
  CHILDREN_LIBRARY_BOOK_VIEWER: '/enfants/bibliotheque/bouquin/:slug',
  CHILDREN_VIDEO_INTRODUCTION: '/enfants/bibliotheque/video/introduction',
  CONTROL_CHILDREN_DETAIL: '/enfants/control-enfant-detail',
  CONTROL_PARENTAL_DETAIL: '/parents/control-parental-detail',
  CONTROL_TEACHERS_DETAIL: '/enseignants/control-ensseignant-detail',
  CONTROL_YOUTH_DETAIL: '/jeunes/control-jeune-detail',
  RICHTEXT_TEST: '/rich-text-test',
  RICHTEXT_IO: '/rich-text-input-output',
  ERREUR: '/erreur',
};
export default PATHS;
