import styled from 'styled-components';
import { colorPalette, fontFamily } from 'stylesheet';

export const CardContainer = styled.div`
  border-radius: 10px;
  padding: 13px;
  background-color: ${colorPalette.white};
`;

export const MediaContainer = styled.div`
  height: auto;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 0px solid ${colorPalette.lightBlueGrey};
  position: relative;
  background-color: white;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    z-index: 1;
  }
`;
interface TitleProps {
  color?: string;
}
export const Title = styled.a<TitleProps>`
  display: flex;
  flex-direction: row;
  gap: 3px;
  font-family: ${fontFamily.main};
  text-decoration: none;
  color: ${(props) => props?.color};
  margin: 5px 0 0 0;
  font-size: 1.4rem;
  font-weight: bold;
`;

export const Description = styled.p`
  font-family: ${fontFamily.main};
  margin: 0;
  font-size: 1rem;
  line-height: normal;
`;

export const ImgLink = styled.a``;
export const VideoImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0px;
`;
export const PictoImg = styled.img`
  position: absolute;
  width: 20%;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 2;
`;
