import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImgPictoUrl from 'assets/png/ico-play.png';
import { useHistory, useLocation } from 'react-router';
import { getPathVideoTheque } from 'utils/Helper';
import {
  SliderItem,
  SliderItemTextContainer,
  SliderItemDescription,
  SliderImages,
  SliderItemImg,
  SliderItemTitle,
  SliderItemButton,
  WrapImgButton,
  PictoImg,
} from './Card.style';

export interface CardProps {
  title: string;
  description: string;
  imgUrl: string;
  alt: string;
  showVideo: () => void;
  profilColor?: string;
}

const Card: React.FC<CardProps> = ({
  description,
  title,
  imgUrl,
  alt,
  showVideo,
  profilColor,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <SliderItem>
      <SliderImages>
        <WrapImgButton onClick={() => showVideo()}>
          <SliderItemImg src={imgUrl} alt={alt} />
          <PictoImg src={ImgPictoUrl} alt={alt} />
        </WrapImgButton>
      </SliderImages>
      <SliderItemTextContainer>
        <SliderItemTitle>{title}</SliderItemTitle>
        <SliderItemDescription>{description}</SliderItemDescription>
        <SliderItemButton
          primaryColor={profilColor}
          onClick={() => history.push(getPathVideoTheque(pathname) || pathname)}
        >
          <FormattedMessage id="videotheque.carousel-slider.see-more-videos" />
        </SliderItemButton>
      </SliderItemTextContainer>
    </SliderItem>
  );
};

export default Card;
