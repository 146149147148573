import ColumnFlex from 'components/ColumnFlex';
import styled from 'styled-components';
import { calcViewWidthPort, TABLET_MAX_WIDTH } from 'stylesheet';

export const ProfilNavContainer = styled.div`
  padding-top: ${calcViewWidthPort(30)};
  padding-bottom: ${calcViewWidthPort(60)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    padding-top: 30px;
  }
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: 0;
  margin-bottom: ${calcViewWidthPort(58)};
  margin-inline-start: ${calcViewWidthPort(165)};
  margin-inline-end: ${calcViewWidthPort(165)};
`;

export const TitleContainer = styled(ColumnFlex)`
  margin-top: 0;
  margin-bottom: ${calcViewWidthPort(20)};
  margin-inline-start: ${calcViewWidthPort(165)};
  margin-inline-end: ${calcViewWidthPort(165)};
`;
