const envSpecificAppConfig = {
  local: {
    googleAnalyticsId: 'G-TER0WLG9LB',
  },
  staging: {
    googleAnalyticsId: 'G-3EFBZBZDVN',
  },
  preprod: {
    googleAnalyticsId: 'G-D7L6B1FFHZ',
  },
  production: {
    googleAnalyticsId: 'G-92XB3RDNNG',
  },
};

const commonAppConfig = {
  adEnvironment: process.env.REACT_APP_ENV || 'local',
  sentryDns: process.env.REACT_APP_SENTRY_DSN,
};

const reactAppConfig = {
  ...commonAppConfig,
  ...envSpecificAppConfig[commonAppConfig.adEnvironment],
};

export default reactAppConfig;
