import Background from 'components/Background';
import ProfilNav from 'components/ProfilNav/ProfilNav';
import PATHS from 'paths';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import {
  getProfileNavInfos,
  PROFIL_HOME_PATHNAME_MAPPING,
  TITLE_STYLE_PROPS_BY_PROFIL,
} from 'pages/services';
import useSelector from 'redux/useSelector';
import CarouselSliderWithPopup from 'components/Generic/CarouselSliderWithPopup/CarouselSliderWithPopup';
import { VideoItem } from 'components/Generic/type';
import VideoTile from 'components/VideoThec/VideoTile/VideoTile';
import ListTileData from 'components/Generic/Data/List/ListTileData';
import { PersonaMap } from 'utils/Constants';
import TitleWithCircle from 'components/TitleWithCircle';
import { ProfilNavContainer, TitleContainer } from './HomeByProfil.style';
import Description from './Description';
import Documentation from './Documentation';
import { fetchVideoListByProfil } from './services';

const HomeByProfil: React.FC<{
  isVideotech?: boolean;
}> = ({ isVideotech = false }) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { language } = useSelector((state) => state.language);
  const [videoData, setVideoData] = useState<VideoItem[]>([]);

  const PROFILS = getProfileNavInfos(
    intl,
    [
      PATHS.CHILDREN_HOME,
      PATHS.PARENTS_HOME,
      PATHS.TEACHERS_HOME,
      PATHS.YOUTH_HOME,
    ].indexOf(pathname),
  );

  PROFILS[0].active = pathname.includes('enfants');
  PROFILS[1].active = pathname.includes('parents');
  PROFILS[2].active = pathname.includes('enseignants');
  PROFILS[3].active = pathname.includes('jeunes');

  useEffect(() => {
    fetchVideoListByProfil(
      language,
      PersonaMap.get(pathname) || { personna: '', filter: 'all' },
    ).then((res) => {
      setVideoData(res);
    });
  }, [pathname]);

  const { colorPrimary, colorSecondary } =
    TITLE_STYLE_PROPS_BY_PROFIL[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {};

  return (
    <Background>
      <ProfilNavContainer>
        <ProfilNav profiles={PROFILS} />
      </ProfilNavContainer>
      {!isVideotech && (
        <>
          <Description />
          <Documentation />
          <CarouselSliderWithPopup listData={videoData.slice(0, 4)} />
        </>
      )}
      {isVideotech && (
        <>
          <TitleContainer>
            <TitleWithCircle
              titleColor={colorPrimary}
              circleColor={colorSecondary}
            >
              <FormattedMessage id="videotheque.title" />
            </TitleWithCircle>
          </TitleContainer>
          <ListTileData
            data={videoData}
            tuile={<VideoTile titleColor={colorPrimary} />}
          />
        </>
      )}
    </Background>
  );
};

export default HomeByProfil;
