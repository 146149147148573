import React from 'react';
import TitleWithCircle from 'components/TitleWithCircle';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from 'components/Tabs';
import {
  DocumentationContainer,
  DocumentationParagraph,
} from 'pages/HomeByProfil/Documentation/Documentation.style';
import { useLocation } from 'react-router';
import useSelector from 'redux/useSelector';
import {
  PROFIL_HOME_PATHNAME_MAPPING,
  TITLE_STYLE_PROPS_BY_PROFIL,
} from 'pages/services';
import {
  getDefaultActiveTabIndex,
  getDocumentationTabsProps,
} from '../services';
import { DocumentationIntlTextsInterface } from '../types';

const Documentation: React.FC = () => {
  const intl = useIntl();
  const { language } = useSelector((state) => state.language);
  const { search, pathname } = useLocation();
  const tabUrlParam = new URLSearchParams(search).get('tab');
  const documentationData = useSelector(
    (state) =>
      (state.homeByProfile[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {})
        .documentation,
  );
  const { colorPrimary, colorSecondary } =
    TITLE_STYLE_PROPS_BY_PROFIL[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {};
  const intlTexts: DocumentationIntlTextsInterface = {
    guides: {
      title: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.guides.title',
      }),
      cta: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.guides.cta-button',
      }),
    },
    advices: {
      title: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.advices.title',
      }),
      cta: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.advices.cta-button',
      }),
    },
    'parent-control': {
      title: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.parent-control.title',
      }),
      cta: intl.formatMessage({
        id: 'home-by-profil.documentation.tabs.parent-control.cta-button',
      }),
    },
  };

  return (
    <DocumentationContainer>
      <TitleWithCircle titleColor={colorPrimary} circleColor={colorSecondary}>
        <FormattedMessage id="home.parent-tuteur.documentation.title" />
      </TitleWithCircle>
      <DocumentationParagraph textAlign="center">
        {documentationData?.docDescription}
      </DocumentationParagraph>
      <Tabs
        tabs={getDocumentationTabsProps(
          PROFIL_HOME_PATHNAME_MAPPING[pathname],
          intlTexts,
          language,
          documentationData?.tabs || [],
        )}
        defaultActiveTabIndex={getDefaultActiveTabIndex(tabUrlParam || '')}
      />
    </DocumentationContainer>
  );
};

export default Documentation;
