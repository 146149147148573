import styled from 'styled-components';

export interface RowFlexProps {
  inline?: boolean;
  fullWidth?: boolean;
  justify?:
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'flex-start';
  align?: 'flex-start' | 'center' | 'stretch' | 'baseline';
}

export const RowFlex = styled.div<RowFlexProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  width: ${(props) => (props.fullWidth ? '100%' : '')};
`;

export default RowFlex;
