import styled from 'styled-components';
import { calcViewWidthPort, colorPalette } from 'stylesheet';
import ColumnFlex from 'components/ColumnFlex';
import H1 from 'components/H1';

export const ErrorPageContainer = styled(ColumnFlex)`
  padding-top: ${calcViewWidthPort(117)};
  padding-bottom: ${calcViewWidthPort(87)};
`;

export const StyledImg = styled.img`
  width: ${calcViewWidthPort(700)};
  height: ${calcViewWidthPort(475)};
`;

export const StyledNotFoundText = styled(H1)`
  text-transform: uppercase;
  color: ${colorPalette.falseIndigo};
`;
