import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VideoTechI {
  selectedVideo: string;
}
const initialState = {
  selectedVideo: '',
};
const videoTechSlice = createSlice({
  name: 'videoTech',
  initialState,
  reducers: {
    setSelectedVideo: (state, action: PayloadAction<string>) => ({
      selectedVideo: action.payload,
    }),
  },
});

export const { setSelectedVideo } = videoTechSlice.actions;
export default videoTechSlice.reducer;
