import { createSlice } from '@reduxjs/toolkit';
import { HomeByProfilState } from './types';
import { PARENTS_DATA, TEACHERS_DATA, YOUTH_DATA } from './mock';

export const initialState: HomeByProfilState = {
  parents: PARENTS_DATA,
  teachers: TEACHERS_DATA,
  youth: YOUTH_DATA,
};

const homeByProfileSlice = createSlice({
  name: 'homeByProfil',
  initialState,
  reducers: {},
});

export default homeByProfileSlice.reducer;
