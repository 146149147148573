import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReferenciel {
  key: string;
  value: string;
}

export type ReferencielState = Readonly<{
  referenciels: IReferenciel[];
}>;

const initialState: ReferencielState = {
  referenciels: [],
};

const referencielSlice = createSlice({
  name: 'referenciel',
  initialState,
  reducers: {
    setReferenciels: (state, action: PayloadAction<IReferenciel[]>) => {
      const draftState = state;
      draftState.referenciels = action.payload;
    },
  },
});

export const { setReferenciels } = referencielSlice.actions;
export default referencielSlice.reducer;
