import GuidesDoc from 'assets/svg/GuidesDoc.svg';
import ConseilsDoc from 'assets/svg/ConseilsDoc.svg';
import GuidesDocAr from 'assets/svg/GuidesDocAr.svg';
import ConseilsDocAr from 'assets/svg/ConseilsDocAr.svg';
import ParentsDoc from 'assets/svg/ParentsDoc.svg';
import TeachersGuidesDoc from 'assets/svg/TeachersGuidesDoc.svg';
import TeachersGuidesDocAr from 'assets/svg/TeachersGuidesDocAr.svg';
import YouthGuidesDoc from 'assets/svg/YouthGuidesDoc.svg';
import YouthGuidesDocAr from 'assets/svg/YouthGuidesDocAr.svg';
import YouthConseilDoc from 'assets/svg/YouthConseilDoc.svg';
import YouthConseilDocAr from 'assets/svg/YouthConseilDocAr.svg';

const lang: any = localStorage.getItem('language');

const fakeParentsDescription =
  '<p class="ql-align-justify">Alors que les services numériques sont en pleine expansion, vous êtes, en tant que parents, de plus en plus préoccupés par les risques en ligne auxquels vos enfants peuvent être sujets.</p></br><p class="ql-align-justify">Conscients de l\'ampleur de ces risques, la plateforme e-Himaya est mise à votre disposition pour accompagner vos enfants dans l’utilisation des technologies numériques, dans l’objectif de garantir un meilleur usage du numérique dans votre famille.</p></br><p class="ql-align-justify">Dans ce sens, cette plateforme vous propose du contenu simple (guides, conseils et techniques, boîtes à outils parental, etc.) et facile à comprendre, montrant les opportunités qu’offre le numérique aux enfants pour développer leurs connaissances et compétences en la matière et comprendre les risques y afférents. </p>';

const fakeParentsDescriptionAr =
  '<p class="ql-align-justify">كلما اتسع نطاق استعمال الخدمات الرقمية، كلما شعرتم، بصفتكم آباء أو أولياء أمور، بقلق متزايد بشأن المخاطر التي قد يتعرض لها أطفالكم عبر الإنترنت.</p></br><p>وإدراكاً لحجم هذه المخاط، فقد تم وضع منصة الحماية الإلكترونية  e-Himaya رهن إشارتكم  والتي ستساعدكم على مواكبة أطفالكم أثناء استخدامهم للتكنولوجيات  الرقمية، وذلك من أجل  ضمان استخدام أأمثل لهذه التكنولوجيات من قبل أفراد أسرتكم.</p></br><p>وتقدم هذه المنصة في هذا الصدد، محتوى مبسطاً وواضحاً (دلائل إرشادية، ونصائح وتقنيات، ومعلومات عن أدوات الرقابة الأبوية، وما إلى ذلك)، يكشف عن الفرص التي تتيحها التكنولوجيا الرقمية للأطفال ومدى مساهمتها في تنمية معارفهم وتوسيعها وتعزيز مهاراتهم في هذا المجال، دون إغفال المخاطر المرتبطة بها.</p>';

const getParentDescription = (language: string) => {
  if (language === 'ar') return fakeParentsDescriptionAr;
  return fakeParentsDescription;
};

const fakeDescriptionForTeachersProfiles = `<p class="ql-align-justify">Culpabiliser les jeunes à propos du mauvais usage du numérique, ne les empêchera pas de manier les outils numériques qui font partie de leur quotidien, ni d’être informés et avertis sur les enjeux et les risques engendrés par ces outils. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Actuellement, il existe plusieurs risques auxquels les enfants et jeunes peuvent être confrontés, notamment des risques techniques (virus, piratage, arnaque, etc.), des risques psycho-sociaux (exposition à des contenus choquants, addiction, cyber harcèlement, etc.), des risques sur la santé (postures, troubles oculaires, exposition aux ondes, etc.) et des risques informationnels (fakenews, rumeurs, etc.).</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Conscients de l’importance de votre rôle d’accompagnateur des enfants et jeunes, nous vous proposons à travers la rubrique ‘Documentation’, un contenu dédié, facile à comprendre, à exploiter et à transmettre aux cibles concernées sous forme de guides, conseils et astuces, contenu pédagogique, et vidéos de sensibilisation.</p>`;

const fakeDescriptionForTeachersProfilesAr = ` إن عتاب أو لوم الأطفال والشباب بشأن سوء استخدامهم للتكنولوجيات والأدوات الرقمية لن يثنيهم عن استخدام هذه الوسائل التي أصبحت تشكل جزءاً من حياتهم اليومية ولن يجعلهم أكثر إلماما ً ولا أكثر حذراً بشأن القضايا والمخاطر المرتبطة بهذه التكنولوجيات.

حيث أن هناك أنواع مختلفة من المخاطر التي يمكن أن تواجه الأطفال والشباب عند استخدام التكنولوجيات والأدوات الرقمية، نذكر منها على الخصوص: المخاطر التقنية (الفيروسات، القرصنة، الاحتيال، إلخ)، والمخاطر النفسية والاجتماعية (التعرض لمحتوى صادم، والإدمان الرقمي، والتنمر الإلكتروني، إلخ)، والمخاطر الصحية (اضطرابات بدنية وبصرية، والتعرض للإشعاعات الإلكترونية، وما إلى ذلك)، فضلاً عن المخاطر المتعلقة بالمعلومات المغلوطة والزائفة.

وإدراكاً لأهمية الأدوار المهمة التي يضطلع بها المدرسون في توجيه وتأطير الأطفال والشباب، تقدم منصة الحماية الإلكترونية e-himaya ، من خلال الجزء " وثائق" أسفله، محتوى موجه لهؤلاء المدرسين، مبسط على شكل: دلائل، جذاذات، نصائح وتقنيات، دروس وتمارين عملية، فيديوهات للدروس، عروض توضيحية وتحسيسية، وسهل الفهم والاستخدام والشرح للفئة المستهدفة (الأطفال والشباب).`;

const getTeachersDescription = (language: string) => {
  if (language === 'ar') return fakeDescriptionForTeachersProfilesAr;
  return fakeDescriptionForTeachersProfiles;
};

const fakeDescriptionForYouthProfiles = `<p class="ql-align-justify">De jour en jour, les jeunes sont vulnérables aux multiples risques auxquels ils peuvent être sujets sur Internet (cyberharcèlement, usurpation d’identité, phishing, etc.). Pour vous aider à comprendre ces risques et apprendre les mesures nécessaires pour les éviter, la plateforme e-Himaya met à votre disposition à travers les rubriques ci-dessous, un ensemble de guides de bonnes pratiques et des conseils de bon usage d’Internet.</p>`;

const fakeDescriptionForYouthProfilesAr = `يتعرض الشباب يومياً لمخاطر الإنترنت (التنمر عبر الإنترنت، وانتحال الشخصية، والتحرش الجنسي عبر الإنترنت، وما إلى ذلك). لمساعدة الشباب والطلبة على فهم هذه المخاطر ومعرفة الإجراءات اللازمة لتفاديها، توفر منصة الحماية الإلكترونية لهذه الفئة، مجموعة من الإرشادات والنصائح من خلال الأجزاء أسفله.`;

const getYouthDescription = (language: string) => {
  if (language === 'ar') return fakeDescriptionForYouthProfilesAr;
  return fakeDescriptionForYouthProfiles;
};

const fakeDocDescriptionText =
  'Ici, vous pouvez consulter ou télécharger des guides de bonnes pratiques, des conseils et techniques que vous pourriez appliquer pour bien encadrer vos enfants en ligne ainsi qu’une boîte à outils de contrôle et filtrage parental vous permettant de paramétrer les outils utilisés par vos enfants.';

const fakeDocDescriptionTextAr =
  'هنا، يمكنكم الاطلاع على دلائل الممارسات الحسنة أو تنزيلها، وعلى النصائح والتقنيات التي  يمكنكم تطبيقها لتأطير استعمال أطفالكم  للإنترنت، بالإضافة إلى مجموعة من  أدوات الرقابة الأبوية والتصفية التي تتيح لكم ضبط إعدادات الأدوات التي يستخدمها أطفالكم.';

const getParentDocDescriptionText = (language: string) => {
  if (language === 'ar') return fakeDocDescriptionTextAr;
  return fakeDocDescriptionText;
};

const fakeDocTeachersDescriptionText = `Dans cette section, vous pouvez consulter ou télécharger des guides de bonnes pratiques, des conseils et astuces vous permettant d’accompagner et aider vos étudiants dans l’usage des outils numériques.
`;

const fakeDocTeachersDescriptionTextAr = `من خلال هذا الجزء، يمكن للمدرس الاطلاع، على دلائل الممارسات الجيدة التي تم إعدادها على شكل جذاذات تتضمن النصائح والتقنيات التي تتيح لك توعية وتحسيس التلاميذ أو الطلبة بالمخاطر الرقمية.
`;

const getTeachersDocDescriptionText = (language: string) => {
  if (language === 'ar') return fakeDocTeachersDescriptionTextAr;
  return fakeDocTeachersDescriptionText;
};

const fakeYouthDocDescriptionText = `Ici, vous pouvez consulter ou télécharger des guides de bonnes pratiques, des conseils et astuces vous permettant d’utiliser des outils et solutions numériques d’une manière sécurisée. 
`;

const fakeYouthDocDescriptionTextAr = `من خلال هذا الجزء، يمكن للشباب والطلبة الاطلاع على بعض الدلائل الإرشادية والنصائح والتقنيات الفضلى وتنزيلها، والتي تمكنهم من معرفة كيفية ضبط إعدادات الأدوات الرقمية، لتفادي الثغرات الأمنية المحتملة التي قد تتسبب في تعرضهم للمخاطر الإلكترونية.
`;

const getYouthDocDescriptionText = (language: string) => {
  if (language === 'ar') return fakeYouthDocDescriptionTextAr;
  return fakeYouthDocDescriptionText;
};

const fakeTabtext =
  '<p class="ql-align-justify">Étant donné l’écart technologique entre les générations, il est essentiel que les parents-tuteurs prennent conscience de l’importance de se mettre à jour pour pouvoir accompagner leurs enfants dans un monde digitalisé.</p><p class="ql-align-justify">C’est pourquoi, un certain nombre de guides ont été élaborés traitant de différentes thématiques (la confidentialité et sécurité en ligne, le temps passé devant les écrans, les jeux vidéo, les réseaux sociaux, etc.) pour vous orienter dans la protection de vos enfants et les sensibiliser aux dangers présents sur Internet tout en gardant à l’œil leur utilisation de cet outil sur les différents appareils numériques tels que le téléphone mobile, l’ordinateur, la tablette, etc.</p><p class="ql-align-justify">Les éditions de ces guides sont non exhaustives ; d’autres guides seraient élaborés traitant les éventuels sujets qui inquiètent les parents-tuteurs au sujet de la protection de leurs enfants.</p><p><br></p>';

const fakeTabtextAr =
  '<p>نظرًا للفجوة التكنولوجية بين الأجيال، أصبح من اللازم أن يدرك الآباء وأولياء الأمور مدى أهمية تحيين معارفهم، فذلك أمر لا مناص منه لمواكبة أطفالهم طيلة مراحل نموهم في عالم أضحى رقمياً بالكامل.</p><br><p>ولهذه الغاية، تم إعداد عدد من الدلائل الإرشادية (الخصوصية والأمن على الإنترنت، المدة الزمنية التي تقضى أمام الشاشات، ألعاب الفيديو وشبكات التواصل الاجتماعي، إلخ) لإرشادكم وتوجيهكم لحماية أطفالكم وتوعيتهم بالمخاطر التي تعج بها الشبكة العنكبوتية، ومراقبة استخدامهم للإنترنت على مختلف الأجهزة الرقمية مثل الهاتف المحمول والحاسوب والجهاز اللوحي وغيرها.</p><br><p>الإصدارات الحالية لهذه الدلائل غير نهائية ، لذا سيتم العمل على إعداد دلائل أخرى لمعالجة باقي القضايا التي من شأنها أن تثير قلق الآباء وأولياء الأمور فيما يتعلق بحماية أطفالهم.</p>';

const getFakeTabText = (language: string) => {
  if (language === 'ar') return fakeTabtextAr;
  return fakeTabtext;
};

const fakeTabTeachersAdvicetext = `<p class="ql-align-justify">Dans cette section, la plateforme e-Himaya vous présente des définitions et explications de certains notions et risques liés aux outils numériques que vous pourriez rencontrer en ligne (phishing, hameçonnage, ransomware, etc.) et qui peuvent avoir un impact négatif sur votre vie personnelle et professionnelle ainsi que celle des apprenants.</p>
<p class="ql-align-justify">Cette plateforme vous propose aussi des conseils et astuces pour être en mesure d’identifier ces risques et accompagner vos apprenants pour les surmonter.</p>
`;

const fakeTabTeachersAdvicetextAr = `<p> عزيزتي المدرسة، عزيزي المدرس، تقدم لكم منصة الحماية الإلكترونية في هذا الجزء بعض النصائح والممارسات الحسنة التي يجب اتباعها لتوعية وتحسيس التلاميذ والطلبة بالمخاطر المتعلقة بالمجال الرقمي وكيفية مواجهتها.
 </p>ويتعلق الأمر:
  <ul> <li> بطاقات وجذاذات تحسيسية.</li> <li> نصائح وتقنيات. </li> <li> دروس وتمارين عملية. </li> <li> مقاطع فيديو للدروس والعروض التوضيحية والتوعوية. </li> </ul>`;

const getFakeTabTeachersAdviceText = (language: string) => {
  if (language === 'ar') return fakeTabTeachersAdvicetextAr;
  return fakeTabTeachersAdvicetext;
};

const fakeTabGuideTeacherstext = `<p class="ql-align-justify">En plus de sa principale mission liée à l’enseignement des apprenants, l’enseignant joue également un rôle primordial dans l’éducation et la sensibilisation de cette génération jeune et connectée. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Dans ce sens, la plateforme e-Himaya offre à l’enseignant, à travers cette section, un ensemble de guides lui permettant de connaitre les risques liés au digital et les bonnes pratiques d’usage approprié de cet outil, et ce, en vue d’être en mesure d’aider et de gérer des situations où un enfant/jeune étudiant pourrait être menacé ou harcelé, et incapable de faire face à une telle situation.</p>`;

const fakeTabGuideTeacherstextAr = `تعتبر مهمة المدرس من بين المهام التي تتطلب الإلمام الجيد بالمناهج التربوية والتوفر على القدرات الضرورية لتأطير وتوجيه التلاميذ والطلبة في مجالات مختلفة، وعلى الخصوص مجال تكنولوجيا المعلومات والاتصال. وفي هذا الصدد، وبالنظر إلى أن الجيل الحالي من الأطفال والشباب جيل دائم التواصل والربط بالأنترنت تقدم المنصة الإلكترونية e-himaya، من خلال هذا الجزء، للمدرسين مجموعة من الدلائل الإرشادية التي تمكن من تدبير المواقف الصعبة من قبيل تعرض الطفل/الشاب للتهديد أو المضايقة على الأنترنت دون أن يكون قادراً على التصدي ومواجهة هذا النوع من المواقف. كما توفر هذه المنصة كذلك أدوات لمواكبة ومساعدة المدرسين في تنظيم أنشطة وحصص دراسية في المجال الرقمي بشكل يجعل التلاميذ أو الطلبة على دراية بالمخاطر التي من شأنهم مواجهتها على الإنترنت.`;

const getFakeTabGuideTeachersText = (language: string) => {
  if (language === 'ar') return fakeTabGuideTeacherstextAr;
  return fakeTabGuideTeacherstext;
};

const fakeGuideYouthTabtext = `<p class="ql-align-justify">Les internautes notamment les jeunes peuvent effectuer toute sorte de manipulation sur Internet et ne diront pas ou ne feront pas, forcément, des choses aimables ou sages. Certains d’entre eux peuvent être volontairement méchants, simplement parce qu’ils se cachent derrière un écran. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Vous ne pouvez pas changer le comportement des autres personnes en ligne, mais vous pouvez bien réfléchir à ce que vous faites ou vous devriez faire.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Pour cela, cette plateforme vous propose des guides de bonnes pratiques sur l’utilisation des outils digitaux vous permettant ainsi de se protéger contre les cybers menaces.</p>
`;

const fakeGuideYouthTabtextAr = `يمكن لرواد الإنترنت استخدام هذه الأداة للقيام لعدة أغراض والتي قد تكون لها في بعض الأحيان تأثيرات سلبية عليهم أو على الغير. قد يكون بعض هؤلاء الرواد لئيماً عن قصد لمجرد كونه متخفي خلف شاشاته. بصفتك شابا أو طالبا، لا يمكنك تغيير سلوك الأشخاص الآخرين عبر الإنترنت، ولكن يمكنك التفكير ملياً فيما تقوم به على الأنترنت أو ما يتوجب عليك القيام به. في هذا الصدد، تقدم هذا الجزء من المنصة بعض دلائل للممارسات الحسنة التي يمكن اعتمادها عند استخدام الأدوات الرقمية، وذلك من أجل نهج السلوك القويم عبر الإنترنت وتبني الأسلوب الأمثل لحماية نفسك من التهديدات الإلكترونية.
`;

const getFakeTabGuideYouthText = (language: string) => {
  if (language === 'ar') return fakeGuideYouthTabtextAr;
  return fakeGuideYouthTabtext;
};

const fakeAdviceYouthTabtext = `
<p class="ql-align-justify">En utilisant le digital, vous pouvez profiter des avantages qu’il offre pour développer vos connaissances et compétences (e-learning, etc.), bénéficier des services en ligne (e-gouvernement, achat en ligne, etc.), et nouer des relations sociales. Néanmoins, et en parallèle de ces avantages, vous pouvez aussi rencontrer des risques en ligne. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">A travers cette section, la plateforme e-Himaya vous propose un éventail de conseils et bonnes pratiques à suivre pour s’autoprotéger de ces risques.</p> `;

const fakeAdviceYouthTabtextAr = `من خلال هذا الجزء، تقدم منصة الحماية الإلكترونية للشباب والطلبة مجموعة من النصائح والممارسات الفضلى التي يمكن اتباعها لحماية نفسهم من المخاطر التي قد يواجهونها على الإنترنت. وتُقدم لهم هذه النصائح على أشكال مختلفة ومختصرة (جذاذات، مقاطع فيديو ...) بناءً على مواقف حقيقية قد تثير انتباههم. سيتم تحيين محتوى هذا الجزء بانتظام.`;

const getFakeTabAdviceYouthText = (language: string) => {
  if (language === 'ar') return fakeAdviceYouthTabtextAr;
  return fakeAdviceYouthTabtext;
};

const fakeAdvicesTabtext = `<p class="ql-align-justify">Internet est une lame à double tranchant pour les enfants et les jeunes. Il constitue un outil très important pour leur apprentissage et leur connaissance, mais aussi un potentiel danger s’ils l’utilisent à mauvais escient. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Cette section présente un certain nombre de conseils sur les principaux risques et sujets concernant les enfants sur Internet, tels que le cyberharcèlement ou la cyberintimidation, le contenu inapproprié, le sexting/ sextorsion, le pédopiégeage en ligne ou grooming, l’usurpation d’identité, la configuration des paramètres  de confidentialité dans les réseaux sociaux et l’enseignement à distance.</p>`;

const fakeAdvicesTabTextAr =
  'إن الإنترنت سيف ذو حدين بالنسبة للأطفال والشباب. فإذا كانت أهميتها تتجلى في كونها وسيلة للتعلم ومصدراً للمعرفة، فإنها تشكل أيضاً خطراً محتملاً في حالة سوء استخدامها. يقدم هذا القسم عددًا من النصائح حول مخاطر الإنترنت وأبرز المواضيع المرتبطة باستخدامها من طرف الأطفال، مثل التحرش الإلكتروني أو التنمر الإلكتروني، والمحتوى غير اللائق، والابتزاز الجنسي، واستمالة الأطفال عبر الإنترنت أو التغرير بهم، وانتحال الشخصية، وضبط إعدادات الخصوصية في شبكات التواصل الاجتماعي والتعليم عن بعد.';

const getFakeAdvicesTabText = (language: string) => {
  if (language === 'ar') return fakeAdvicesTabTextAr;
  return fakeAdvicesTabtext;
};

const ParentalControlText = `<p class="ql-align-justify">Il existe de nombreux outils et applications sur le marché permettant le contrôle parental pour protéger les enfants en ligne. </p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Cette section vous présente des informations utiles sur certains outils et applications notamment les aspects techniques liés à leur activation, installation et/ou configuration afin de vous permettre d’exercer ce contrôle et encadrer l'usage des outils digitaux par vos enfants.</p><p><br></p>
`;

const ParentalControlTextAr =
  'توجد هناك العديد من الحلول والتطبيقات الخاصة بالرقابة الأبوية على الإنترنت. يقدم هذا القسم معلومات وتوصيات تقنية لتثبيت وضبط إعدادات الأدوات الرقمية المتعلقة بالرقابة الأبوية ويقترح حلولاً عملية للقيام بهذا الأمر على النحو الأمثل.';

const getParentalControlText = (language: string) => {
  if (language === 'ar') return ParentalControlTextAr;
  return ParentalControlText;
};

export const PARENTS_DATA = {
  description: getParentDescription(lang),
  documentation: {
    docDescription: getParentDocDescriptionText(lang),
    tabs: [
      {
        label: 'guides',
        text: getFakeTabText(lang),
      },
      {
        label: 'advices',
        text: getFakeAdvicesTabText(lang),
      },
      {
        label: 'parent-control',
        text: getParentalControlText(lang),
      },
    ],
  },
};

export const TEACHERS_DATA = {
  description: getTeachersDescription(lang),
  documentation: {
    docDescription: getTeachersDocDescriptionText(lang),
    tabs: [
      {
        label: 'guides',
        text: getFakeTabGuideTeachersText(lang),
      },
      {
        label: 'advices',
        text: getFakeTabTeachersAdviceText(lang),
      },
    ],
  },
};

export const YOUTH_DATA = {
  description: getYouthDescription(lang),
  documentation: {
    docDescription: getYouthDocDescriptionText(lang),
    tabs: [
      {
        label: 'guides',
        text: getFakeTabGuideYouthText(lang),
      },
      {
        label: 'advices',
        text: getFakeTabAdviceYouthText(lang),
      },
    ],
  },
};
export const getImgByProfilAndLaguage = (profil: string): string[] => {
  switch (profil) {
    case 'parents':
      if (lang === 'ar') return [GuidesDocAr, ConseilsDocAr, ParentsDoc];
      return [GuidesDoc, ConseilsDoc, ParentsDoc];
    case 'teachers':
      if (lang === 'ar') return [TeachersGuidesDocAr, ConseilsDocAr];
      return [TeachersGuidesDoc, ConseilsDoc];
    case 'youth':
      if (lang === 'ar') return [YouthGuidesDocAr, YouthConseilDocAr];
      return [YouthGuidesDoc, YouthConseilDoc];
    default:
      return [];
  }
};
