import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitiativeDetails, IInitiaveSlide, ILightInitiative } from './type';

export type InitiativesState = Readonly<{
  initiativeSlides: IInitiaveSlide[];
  initiatives: ILightInitiative[];
  selectedInitiativeDetails: IInitiativeDetails;
  totalInitiatives: number;
}>;

const initialState: InitiativesState = {
  initiativeSlides: [],
  initiatives: [],
  selectedInitiativeDetails: {
    id: 0,
    detailsText: '',
    detailsTitle: '',
    fileName: '',
    slug: '',
  },
  totalInitiatives: 0,
};

const initiativeSlice = createSlice({
  name: 'initiatives',
  initialState,
  reducers: {
    setInitiativeSlides: (state, action: PayloadAction<IInitiaveSlide[]>) => {
      const draftState = state;
      draftState.initiativeSlides = action.payload;
    },
    setInitiatives: (state, action: PayloadAction<ILightInitiative[]>) => {
      const draftState = state;
      draftState.initiatives = action.payload;
    },
    setSelectedInitiativeDetails: (
      state,
      action: PayloadAction<IInitiativeDetails>,
    ) => {
      const draftState = state;
      draftState.selectedInitiativeDetails = action.payload;
    },
    setTotalInitiatives: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalInitiatives = action.payload;
    },
  },
});

export const {
  setInitiativeSlides,
  setInitiatives,
  setSelectedInitiativeDetails,
  setTotalInitiatives,
} = initiativeSlice.actions;
export default initiativeSlice.reducer;
