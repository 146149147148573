import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdviceDetails, IAdvice } from './type';

export type AdviceState = Readonly<{
  advices: IAdvice[];
  selectedAdviceDetails: IAdviceDetails;
  totalAdvices: number;
}>;

const initialState: AdviceState = {
  advices: [],
  selectedAdviceDetails: {
    id: 0,
    title: '',
    block1: '',
    block2: '',
    block3: '',
  },
  totalAdvices: 0,
};

const adviceSlice = createSlice({
  name: 'advices',
  initialState,
  reducers: {
    setAdvices: (state, action: PayloadAction<IAdvice[]>) => {
      console.log('payload', action.payload);
      const draftState = state;
      draftState.advices = action.payload;
    },
    setSelectedAdviceDetails: (
      state,
      action: PayloadAction<IAdviceDetails>,
    ) => {
      const draftState = state;
      draftState.selectedAdviceDetails = action.payload;
    },
    setTotalAdvices: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalAdvices = action.payload;
    },
  },
});

export const {
  setAdvices,
  setSelectedAdviceDetails,
  setTotalAdvices,
} = adviceSlice.actions;
export default adviceSlice.reducer;
