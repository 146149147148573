import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WordingState = Readonly<Record<string, unknown>>;
const initialState = {};

const wordingSlice = createSlice({
  name: 'Wording',
  initialState,
  reducers: {
    setWording: (state, action: PayloadAction<WordingState>) => action.payload,
  },
});

export const { setWording } = wordingSlice.actions;
export default wordingSlice.reducer;
