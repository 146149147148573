import { colorPalette, fontFamily, calcViewWidthPort } from 'stylesheet';

const theme = {
  richTextRenderer: {
    direction: {
      ar: 'rtl',
      fr: 'ltr',
    },
    textAlign: {
      ar: 'start',
      fr: 'end',
    },
    lineHeight: {
      ar: calcViewWidthPort(30),
      fr: calcViewWidthPort(19),
    },
  },
  headerLanguage: {
    fontFamily: {
      ar: fontFamily.arabic,
      fr: fontFamily.main,
    },
    color: {
      ar: colorPalette.orange,
      fr: colorPalette.black,
    },
  },
  homePage: {
    header: {
      links: {
        color: {
          ar: colorPalette.cobaltBlue,
          fr: colorPalette.cyan4,
        },
      },
    },
    homeDescription: {
      fontFamily: {
        ar: fontFamily.arabic,
        fr: fontFamily.main,
      },
      fontWeight: {
        ar: 'bold',
        fr: 'normal',
      },
    },
    homeActuality: {
      lineHeight: {
        ar: calcViewWidthPort(30),
        fr: calcViewWidthPort(19),
      },
    },
  },
  actualityPage: {
    lineHeight: {
      ar: calcViewWidthPort(30),
      fr: calcViewWidthPort(19),
    },
    fontFamily: {
      ar: fontFamily.arabic,
      fr: fontFamily.main,
    },
    fontWeight: {
      ar: 'bold',
      fr: 'normal',
    },
  },
  pagination: {
    navArrow: {
      paddingTop: {
        ar: 0,
        fr: calcViewWidthPort(5),
      },
    },
  },
  childStyle: {
    fontFamily: {
      ar: fontFamily.arabic,
      fr: fontFamily.main,
    },
    fontWeight: {
      ar: 'bold',
      fr: 'normal',
    },
  },
  buttonStyle: {
    fontFamily: {
      ar: fontFamily.arabic,
      fr: fontFamily.main,
    },
    fontWeight: {
      ar: 'bold',
      fr: 'normal',
    },
  },
  lineHeight: {
    ar: calcViewWidthPort(30),
    fr: calcViewWidthPort(19),
  },
  boldFont: {
    fontFamily: {
      ar: fontFamily.arabic_bold,
      fr: fontFamily.main,
    },
  },
};

export default theme;
