import styled from 'styled-components';
import ColumnFlex from 'components/ColumnFlex';
import Paragraph from 'components/Paragraph';
import {
  calcViewWidthPort,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  typography,
} from 'stylesheet';

export const DocumentationContainer = styled(ColumnFlex)`
  margin-top: 0;
  margin-bottom: ${calcViewWidthPort(110)};
  margin-inline-start: ${calcViewWidthPort(165)};
  margin-inline-end: ${calcViewWidthPort(165)};
`;

export const DocumentationParagraph = styled(Paragraph)`
  ${typography.xmedium}
  margin-bottom: ${calcViewWidthPort(30)};
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: 14px;
    line-height: 18px;
  }
`;
