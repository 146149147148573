/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { LocationChangeAction, RouterState } from 'connected-react-router';
import { CombinedState, combineReducers, Reducer } from 'redux';
import { reducer as wording } from './Wording';
import { reducer as guides } from './Guides';
import { reducer as homeByProfile } from './HomeByProfil';
import { reducer as usefulLinks } from './UsefulLinks';
import { reducer as initiatives } from './Initiatives';
import { reducer as actualities } from './Actualities';
import { reducer as glossary } from './Glossary';
import { reducer as language } from './Language';
import { reducer as partners } from './Partners';
import { reducer as referenciel } from './Referenciel';
import { reducer as childGuides } from './ChildGuides';
import { reducer as videoTech } from './Videotech';
import { reducer as quizzes } from './Quiz';
import { reducer as questions } from './Question';
import { reducer as advices } from './Advices';
import { reducer as parentalControls } from './ParentalControls';
import { RootState } from './types';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export function createReducer(asyncReducers: {
  router: Reducer<RouterState, LocationChangeAction>;
}): Reducer<CombinedState<RootState>> {
  return combineReducers<RootState>({
    ...asyncReducers,
    wording,
    guides,
    homeByProfile,
    usefulLinks,
    initiatives,
    actualities,
    glossary,
    language,
    partners,
    referenciel,
    childGuides,
    videoTech,
    quizzes,
    questions,
    advices,
    parentalControls,
  });
}
