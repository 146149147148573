import { useAsyncFn } from 'react-use';
import client from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { setUsefulLinks, UsefulLinkI } from './slice';

export const useFetchUsefulLinks = () => {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const result = await client.get(`/useful-links/footer`);
    if (result.status === 200) {
      const usefulLinks: UsefulLinkI[] = result.body as UsefulLinkI[];
      dispatch(setUsefulLinks(usefulLinks));
    }
  });
};
