/* eslint-disable complexity */
import PATHS from 'paths';
import { colorPalette } from 'stylesheet';
import ParentsHomePageImg from 'assets/svg/ParentsHomePageImg.svg';
import TeachersHomePageImg from 'assets/svg/TeachersHomePageImg.svg';
import YouthHomePageImg from 'assets/svg/YouthHomePageImg.svg';
import { ProfilType } from 'redux/types';
import {
  DocumentationTabContentInterface,
  DocumentationTabType,
} from 'redux/HomeByProfil/types';
import { each } from 'lodash';
import { SingleTabInterface } from 'components/Tabs/Tabs.style';
import { getImgByProfilAndLaguage } from 'redux/HomeByProfil/mock';
import { useFetchDataFrom } from 'redux/Shared/hooks';
import { LanguageEnum } from 'redux/Language';
import { addRelParamToVideoLink, getVideoImage } from 'utils/Helper';
import { PersonnaMapType } from 'utils/Constants';
import {
  DescriptionImageByProfilInterface,
  DocumentationIntlTextsInterface,
} from './types';

export const DESCRIPTION_IMAGE_BY_PROFIL: DescriptionImageByProfilInterface = {
  parents: ParentsHomePageImg,
  teachers: TeachersHomePageImg,
  youth: YouthHomePageImg,
};

export const TabEnum = ['guides', 'conseils', 'control-parental'];

export const getDefaultActiveTabIndex = (tabUrlParam: string): number => {
  return !TabEnum.includes(tabUrlParam) ? 0 : TabEnum.indexOf(tabUrlParam);
};

const parentsTabImgs: string[] = getImgByProfilAndLaguage('parents');
const teachersTabImgs: string[] = getImgByProfilAndLaguage('teachers');
const youthTabImgs: string[] = getImgByProfilAndLaguage('youth');

export const getDocumentationTabsProps = (
  profil: ProfilType,
  intlTexts: DocumentationIntlTextsInterface,
  language: string,
  tabsContents: DocumentationTabContentInterface[],
): SingleTabInterface[] => {
  const tabsContentMappedByLabel: {
    [label in DocumentationTabType]?: string;
  } = {};
  each(tabsContents, ({ label, text }) => {
    tabsContentMappedByLabel[label] = text;
  });
  switch (profil) {
    case 'parents':
      return [
        {
          title: intlTexts.guides.title,
          colorPrimary: colorPalette.cyan4,
          colorSecondary: colorPalette.aquamarine,
          imgUrl: parentsTabImgs[0],
          buttonLabel: intlTexts.guides.cta,
          link: PATHS.PARENTS_GUIDES,
          text: tabsContentMappedByLabel.guides || '',
          isDynamicFromApi: false,
          staticContentKey: 'no-key-for-this-item',
        },
        {
          title: intlTexts.advices.title,
          colorPrimary: colorPalette.cyan4,
          colorSecondary: colorPalette.aquamarine,
          imgUrl: parentsTabImgs[1],
          buttonLabel: intlTexts.advices.cta,
          link: PATHS.PARENTS_ADVICES,
          text: tabsContentMappedByLabel.advices || '',
          isDynamicFromApi: true,
          staticContentKey: 'CONSEILS_PARENTS_INTRODUCTION',
        },
        {
          title: intlTexts['parent-control'].title,
          colorPrimary: colorPalette.cyan4,
          colorSecondary: colorPalette.aquamarine,
          imgUrl: parentsTabImgs[2],
          buttonLabel: intlTexts['parent-control'].cta,
          link: PATHS.PARENTS_PARENTAL_CONTROL,
          text: tabsContentMappedByLabel['parent-control'] || '',
          isDynamicFromApi: true,
          staticContentKey: 'PARENTAL_CONTROL_FIRST_INTRODUCTION',
        },
      ];
    case 'teachers':
      return [
        {
          title: intlTexts.guides.title,
          colorPrimary: colorPalette.cyan5,
          colorSecondary: colorPalette.lightGreen,
          imgUrl: teachersTabImgs[0],
          buttonLabel: intlTexts.guides.cta,
          link: PATHS.TEACHERS_GUIDES,
          text: tabsContentMappedByLabel.guides || '',
          isDynamicFromApi: false,
          staticContentKey: 'no-key-for-this-item',
        },
        {
          title: intlTexts.advices.title,
          colorPrimary: colorPalette.cyan5,
          colorSecondary: colorPalette.lightGreen,
          imgUrl: teachersTabImgs[1],
          buttonLabel: intlTexts.advices.cta,
          link: PATHS.TEACHERS_ADVICES,
          text: tabsContentMappedByLabel.advices || '',
          isDynamicFromApi: true,
          staticContentKey: 'CONSEILS_TEACHERS_INTRODUCTION',
        },
      ];
    case 'youth':
      return [
        {
          title: intlTexts.guides.title,
          colorPrimary: colorPalette.falseIndigo,
          colorSecondary: colorPalette.aquamarine,
          imgUrl: youthTabImgs[0],
          buttonLabel: intlTexts.guides.cta,
          link: PATHS.YOUTH_GUIDES,
          text: tabsContentMappedByLabel.guides || '',
          isDynamicFromApi: false,
          staticContentKey: 'no-key-for-this-item',
        },
        {
          title: intlTexts.advices.title,
          colorPrimary: colorPalette.falseIndigo,
          colorSecondary: colorPalette.aquamarine,
          imgUrl: youthTabImgs[1],
          buttonLabel: intlTexts.advices.cta,
          link: PATHS.YOUTH_ADVICES,
          text: tabsContentMappedByLabel.advices || '',
          isDynamicFromApi: true,
          staticContentKey: 'CONSEILS_YOUTH_INTRODUCTION',
        },
      ];
    default:
      return [];
  }
};

export const fetchVideoListByProfil = (
  language: LanguageEnum,
  profile: PersonnaMapType,
) => {
  const fetchData = useFetchDataFrom;
  const arrayHelp: any[] = [];
  return fetchData(`videotheque/${profile.filter}/${profile.personna}`).then(
    (res) => {
      const bodyArray: any[] = res.body;
      if (Array.isArray(bodyArray))
        bodyArray.forEach((next) => {
          arrayHelp.push({
            title: language === LanguageEnum.fr ? next.titleFr : next.titleAr,
            description:
              language === LanguageEnum.fr
                ? next.descriptionFr
                : next.descriptionAr,
            descriptionSlided:
              language === LanguageEnum.fr
                ? next.descriptionSlidedFr
                : next.descriptionSlidedAr,
            persona: next.persona,
            videoLink: addRelParamToVideoLink(next.videoLink),
            imgUrl: getVideoImage(next.videoLink),
          });
        });
      return arrayHelp;
    },
  );
};
