import 'babel-polyfill';
import React from 'react';
import GA4React from 'ga-4-react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import { buildStore as configureStore } from './redux/store';
import reactAppConfig from './reactAppConfig';

Sentry.init({
  dsn: reactAppConfig.sentryDns,
  environment: reactAppConfig.adEnvironment,
});

const ga4react = new GA4React(reactAppConfig.googleAnalyticsId);
ga4react.initialize();

const history = createBrowserHistory();
const { store, persistor } = configureStore(history);

const rootEl = document.getElementById('root');

if (rootEl) {
  ReactDOM.render(
    <App history={history} store={store} persistor={persistor} />,
    rootEl,
  );
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line
    if (rootEl) {
      ReactDOM.render(
        <NextApp history={history} store={store} persistor={persistor} />,
        rootEl,
      );
    }
  });
}
