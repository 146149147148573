import { useAsyncFn } from 'react-use';
import client from 'services/networking/client';
import { useDispatch } from 'react-redux';
import { IReferenciel, setReferenciels } from './slice';

export const useFetchReferenciels = () => {
  const dispatch = useDispatch();
  return useAsyncFn(async () => {
    const result = await client.get(`/referenciel/light`);
    if (result.status === 200) {
      const refs: IReferenciel[] = result.body.content as IReferenciel[];
      dispatch(setReferenciels(refs));
    }
  });
};
