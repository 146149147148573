import styled from 'styled-components';
import { Button } from '@material-ui/core';
import {
  calcViewWidthPort,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from 'stylesheet';
import { ReactComponent as NextIcon } from 'assets/svg/RightIcon.svg';
import { ReactComponent as PrevIcon } from 'assets/svg/LeftIcon.svg';
import MUICarousel from 'react-material-ui-carousel';
import { LanguageEnum } from 'redux/Language';

interface NavButtonInterface {
  $next: boolean;
  $prev: boolean;
}
export const CarouselButton = styled(Button)<NavButtonInterface>`
  background-color: transparent;
  top: ${calcViewWidthPort(120)};
  overflow: unset;
  width: ${calcViewWidthPort(16)};
  height: ${calcViewWidthPort(32)};
`;

export const NextIconImg = styled(NextIcon)`
  width: ${calcViewWidthPort(16)};
  height: ${calcViewWidthPort(32)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    width: 12px;
    height: 20px;
    position: absolute;
    right: 10px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 8px;
    height: 16px;
    position: absolute;
    right: 10px;
  }
`;

export const PrevIconImg = styled(PrevIcon)`
  width: ${calcViewWidthPort(16)};
  height: ${calcViewWidthPort(32)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    width: 12px;
    height: 20px;
    position: absolute;
    left: 10px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 8px;
    height: 16px;
    position: absolute;
    left: 10px;
  }
`;

interface MUICarouselStyledProps {
  lang: LanguageEnum;
}

export const MUICarouselStyled = styled(MUICarousel)<MUICarouselStyledProps>`
  width: 100%;
  [class*='Carousel-prev'] {
    left: unset;
    inset-inline-start: 0 !important;
    transform: ${(props) =>
      props.lang === LanguageEnum.ar ? 'rotateZ(180deg)' : ''};
  }
  [class*='Carousel-next'] {
    right: unset;
    inset-inline-end: 0 !important;
    transform: ${(props) =>
      props.lang === LanguageEnum.ar ? 'rotateZ(180deg)' : ''};
  }
`;
