import Paragraph from 'components/Paragraph';
import styled from 'styled-components';
import { calcViewWidthPort, MOBILE_MAX_WIDTH } from 'stylesheet';
import RowFlex from 'components/RowFlex';
import ColumnFlex from 'components/ColumnFlex';

export const RelativeContainer = styled.div`
  position: relative;
`;

export const TitleContainer = styled(RowFlex)`
  margin-bottom: ${calcViewWidthPort(30)};
`;

export const StyledParagraph = styled(Paragraph)`
  width: ${calcViewWidthPort(730)};
  white-space: pre-line;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`;

export const ImgCenteredFlexContainer = styled(ColumnFlex)`
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  bottom: 0;
  width: ${calcViewWidthPort(318)};
  align-items: flex-end;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    position: static;
    text-align: center;
    width: 100%;
  }
`;

export const StyledSVGImg = styled.img`
  width: ${calcViewWidthPort(318)};
  height: ${calcViewWidthPort(280.5)};
  margin-top: ${calcViewWidthPort(100)};
  margin-inline-end: ${calcViewWidthPort(10)};
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 0.5rem auto 1rem auto;
  }
`;
