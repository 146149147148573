import styled from 'styled-components';
import { ReactComponent as TitleCircleImg } from 'assets/svg/TitleCircleImg.svg';
import H1 from 'components/H1';
import {
  calcViewWidthPort,
  colorPalette,
  TABLET_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
} from 'stylesheet';

export const TitleWithCircleContainer = styled.div`
  position: relative;
  display: flex;
  padding-top: ${calcViewWidthPort(21)};
  padding-inline-start: ${calcViewWidthPort(21)};
`;

interface PositionnedCircleProps {
  color?: string;
}

export const PositionnedCircle = styled(TitleCircleImg)<PositionnedCircleProps>`
  width: ${calcViewWidthPort(42)};
  height: ${calcViewWidthPort(42)};
  z-index: -1;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  color: ${(props) => props.color || colorPalette.aquamarine};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    width: 25px;
    height: 25px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 15px;
    height: 15px;
  }
`;

export const Title = styled(H1)`
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-family: ${(props) => props.theme.boldFont.fontFamily};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    font-size: 24px;
    line-height: 22px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: 20px;
  }
`;
