import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from 'assets/svg/ArrowUp.svg';
import StyledButton from './ScrollToTopButton.style';

export const ScollToTopWhen = 400;

const ScrollToTopButton: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onScrollHandler = () => {
    if (!show && window.pageYOffset > ScollToTopWhen) {
      setShow(true);
    } else if (show && window.pageYOffset <= ScollToTopWhen) {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [onScrollHandler]);

  return (
    <>
      {show && (
        <StyledButton onClick={scrollToTop} data-testid="button">
          <ArrowUp />
        </StyledButton>
      )}
    </>
  );
};

export default ScrollToTopButton;
