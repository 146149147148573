import SimpleLink from 'components/SimpleLink';
import styled from 'styled-components';
import {
  calcViewWidthPort,
  colorPalette,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  typography,
} from 'stylesheet';

interface Props {
  color: string;
}

const ProfilLinkButton = styled(SimpleLink)<Props>`
  ${typography.xmedium};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  margin-inline-start: ${calcViewWidthPort(10)};
  margin-inline-end: ${calcViewWidthPort(10)};
  border-radius: ${calcViewWidthPort(100)};
  width: ${calcViewWidthPort(250)};
  height: ${calcViewWidthPort(100)};
  color: ${(props) => props.color};
  background-color: ${colorPalette.white};
  border: ${calcViewWidthPort(3)} solid ${(props) => props.color};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    width: 150px;
    height: 50px;
    font-weight: bold;
    font-size: 18px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: 16px;
  }
  &.active {
    color: ${colorPalette.white};
    background-color: ${(props) => props.color};
  }
`;

export default ProfilLinkButton;
