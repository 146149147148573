import React, { useEffect } from 'react';
import {
  SingleTabInterface,
  TabsContainer,
  NavTabs,
  TabBody,
  TabElement,
  TabMain,
  TabButton,
  TabImg,
  TabText,
  RichTextRendererContainer,
} from 'components/Tabs/Tabs.style';
import SimpleLink from 'components/SimpleLink';
import { useLocation } from 'react-router';
import RichTextRenderer from 'components/RichTextRenderer';
import useSelector from 'redux/useSelector';
import StaticContentByKey from 'components/StaticContentByKey/StaticContentByKey';

interface TabsInterface {
  tabs: SingleTabInterface[];
  defaultActiveTabIndex: number;
}
const Tabs: React.FC<TabsInterface> = ({ tabs, defaultActiveTabIndex }) => {
  const { language } = useSelector((state) => state.language);
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = React.useState<SingleTabInterface>(
    tabs[defaultActiveTabIndex],
  );
  const [activeIndex, setActiveIndex] = React.useState(defaultActiveTabIndex);
  const handleTabClick = (targetIndex: number) => {
    setActiveTab(tabs[targetIndex]);
    setActiveIndex(targetIndex);
  };
  useEffect(() => {
    setActiveTab(tabs[defaultActiveTabIndex]);
    setActiveIndex(defaultActiveTabIndex);
  }, [pathname, tabs]);
  return (
    <TabsContainer>
      <NavTabs>
        {tabs.map((tab, index) => (
          <TabElement
            key={tab.title}
            primaryColor={tab.colorPrimary}
            secondaryColor={tab.colorSecondary}
            index={index}
            max={tabs.length}
            active={activeIndex === index}
            lang={language}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </TabElement>
        ))}
      </NavTabs>
      <TabBody>
        <TabImg
          src={activeTab?.imgUrl}
          alt={`Children Online Protection ${activeTab?.title}`}
        />
        <TabMain align="flex-start">
          <TabText>
            {activeTab?.isDynamicFromApi ? (
              <RichTextRendererContainer>
                <StaticContentByKey
                  staticContentKey={activeTab?.staticContentKey ?? 'no-key'}
                />
              </RichTextRendererContainer>
            ) : (
              <RichTextRenderer html={activeTab?.text} />
            )}
          </TabText>
          <SimpleLink to={activeTab?.link}>
            <TabButton backgroundColor={activeTab?.colorPrimary}>
              {activeTab?.buttonLabel}
            </TabButton>
          </SimpleLink>
        </TabMain>
      </TabBody>
    </TabsContainer>
  );
};
export default Tabs;
