import styled from 'styled-components';
import { colorPalette, typography } from 'stylesheet';

interface H1Props {
  color?: string;
}

const H1 = styled.h1<H1Props>`
  ${typography.xxlarge};
  color: ${(props) => props.color || colorPalette.cobaltBlue};
`;

export default H1;
