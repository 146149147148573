import styled from 'styled-components';
import {
  colorPalette,
  calcViewWidthPort,
  typography,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  BETWEEN_TABLET_MOBILE_WIDTH,
} from 'stylesheet';
import RowFlex from 'components/RowFlex';
import { ReactComponent as Ellipse } from 'assets/svg/Ellipse.svg';
import Button from '@material-ui/core/Button';

export const SliderItem = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 40px;
  @media screen and (max-width: ${BETWEEN_TABLET_MOBILE_WIDTH}) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const SliderItemTextContainer = styled.div`
  margin-inline-start: ${calcViewWidthPort(65)};
  @media screen and (max-width: ${BETWEEN_TABLET_MOBILE_WIDTH}) {
    margin: auto;
    text-align: center;
    width: calc(100% - 40px);
    margin-inline-start: 0;
    padding: 0 20px;
  }
`;

export const SliderItemDescription = styled.div`
  ${typography.xmedium}
  margin-top: ${calcViewWidthPort(20)};
  width: ${calcViewWidthPort(700)};
  text-align: justify;
  line-height: ${(props) => props.theme.lineHeight};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    line-height: normal;
    width: auto;
    ${typography.xmediumTablet}
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    line-height: normal;
    ${typography.xmediumMobile}
    width: calc(100% + 40px);
  }
`;

export const SliderImages = styled(RowFlex)`
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
  }
`;

export const PictoImg = styled.img`
  position: absolute;
  width: 20%;
  z-index: 2;
`;

export const EllipseImg = styled(Ellipse)`
  position: relative;
  width: ${calcViewWidthPort(112)};
  height: ${calcViewWidthPort(112)};
  top: ${calcViewWidthPort(90)};
  inset-inline-end: ${calcViewWidthPort(115)};
`;

export const SliderItemImg = styled.img`
  width: ${calcViewWidthPort(380)};
  height: auto;
  border-radius: 5px;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    width: calc(100% - 60px);
    margin: auto;
  }
`;

export const WrapImgButton = styled(Button)`
  padding: 8px !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 0.55vw 0.5vw;
  background: white !important;
  position: relative;
  &:before {
    position: absolute;
    left: 8px;
    top: 8px;
    bottom: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    z-index: 1;
  }
`;

export const SliderItemTitle = styled.div`
  ${typography.large}
  color: ${colorPalette.black};
  margin-top: ${calcViewWidthPort(32)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    ${typography.largeTablet}
    margin-left: auto;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    ${typography.largeMobile}
    width: calc(100% + 40px);
  }
`;
interface SliderItemButtonProps {
  primaryColor: any;
}
export const SliderItemButton = styled.a<SliderItemButtonProps>`
  width: fit-content;
  white-space: nowrap;
  color: ${(props) => props.primaryColor};
  padding: 0 30px;
  inset-inline-end: 0vw;
  position: relative;
  display: flex;
  align-items: center;
  ${typography.medium}
  height: ${calcViewWidthPort(48)};
  border-radius: ${calcViewWidthPort(30)};
  border: 1.5px solid ${(props) => props.primaryColor};
  background-color: ${colorPalette.white};
  margin-top: 20px;
  margin-bottom: 0;
  cursor: pointer;
  :hover {
    color: ${colorPalette.white};
    background-color: ${(props) => props.primaryColor};
  }
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    ${typography.mediumMobile}
    width: fit-content;
    height: 25px;
    border-radius: 10px;
    margin: 15px auto;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    margin: 15px auto 0 auto;
  }
`;
