import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum LanguageEnum {
  fr = 'fr',
  ar = 'ar',
}

export type LanguageState = Readonly<{
  language: LanguageEnum;
}>;

export const getLanguage = (): LanguageEnum => {
  const lang = localStorage.getItem('language')?.toLowerCase();

  switch (lang) {
    case 'fr':
      return LanguageEnum.fr;
    case 'ar':
      return LanguageEnum.ar;
    default:
      return LanguageEnum.fr;
  }
};

const initialState: LanguageState = {
  language: getLanguage(),
};

const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageEnum>) => {
      const draftState = state;
      draftState.language = action.payload;
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;
export default LanguageSlice.reducer;
