import React from 'react';
import dompurify from 'dompurify';
import { RichTextRendererContainer } from './RichTextRenderer.style';

interface RichTextRendererInterface {
  html: string | undefined;
}

const RichTextRenderer: React.FC<RichTextRendererInterface> = ({ html }) => {
  dompurify.addHook('afterSanitizeAttributes', (node: Element) => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });
  const sanitizedHTMLText = { __html: dompurify.sanitize(html || '') };
  return (
    <RichTextRendererContainer dangerouslySetInnerHTML={sanitizedHTMLText} />
  );
};
export default RichTextRenderer;
