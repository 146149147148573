import React from 'react';
import {
  PositionnedCircle,
  Title,
  TitleWithCircleContainer,
} from './TitleWithCircle.style';

interface TitleWithCircleProps {
  circleColor?: string;
  titleColor?: string;
}

const TitleWithCircle: React.FC<TitleWithCircleProps> = ({
  children,
  circleColor,
  titleColor,
}) => {
  return (
    <TitleWithCircleContainer data-testid="container">
      <PositionnedCircle color={circleColor} data-testid="circle-svg" />
      <Title color={titleColor}>{children}</Title>
    </TitleWithCircleContainer>
  );
};

export default TitleWithCircle;
