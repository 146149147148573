import PATHS from 'paths';

export const addRelParamToVideoLink = (url: string): string => {
  return `${url}&rel=0`;
};
export const getVideoId = (url: string): string | null => {
  const urlHelp = new URL(url);
  const searchParmas = new URLSearchParams(urlHelp.search);
  return searchParmas.get('v');
};
export const getVideoImage = (videoLink: string): string => {
  return `https://i.ytimg.com/vi_webp/${getVideoId(
    videoLink,
  )}/maxresdefault.webp`;
};
export const getPathVideoTheque = (pathname: string): string | undefined => {
  const PathsMap = new Map<string, string>([
    [PATHS.PARENTS_HOME, PATHS.PARENTS_VIDEOTECH],
    [PATHS.TEACHERS_HOME, PATHS.TEACHERS_VIDEOTECH],
    [PATHS.YOUTH_HOME, PATHS.YOUTH_VIDEOTECH],
  ]);
  return PathsMap.get(pathname);
};
