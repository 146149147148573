import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILightGuide {
  id: number;
  imageName: string;
  title: string;
  miniSummary: string;
  slug: string;
}

export interface IGuide {
  lightGuide: ILightGuide;
  summary: string;
  description: string;
}

export interface ISelectedGuideDetails {
  imageName: string;
  title: string;
  summary: string;
  miniSummary: string;
  description: string;
  slug: string;
  fileName: string;
}

export type GuidesState = Readonly<{
  data: ILightGuide[];
  selectedGuideDetails: ISelectedGuideDetails;
}>;

const initialState: GuidesState = {
  data: [],
  selectedGuideDetails: {
    imageName: '',
    title: '',
    summary: '',
    miniSummary: '',
    description: '',
    slug: '',
    fileName: '',
  },
};

const guidesSlice = createSlice({
  name: 'guides',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ILightGuide[]>) => {
      const draftState = state;
      draftState.data = action.payload;
    },
    setSelectedGuideDetails: (
      state,
      action: PayloadAction<ISelectedGuideDetails>,
    ) => {
      const draftState = state;
      draftState.selectedGuideDetails = action.payload;
    },
  },
});

export const { setData, setSelectedGuideDetails } = guidesSlice.actions;
export default guidesSlice.reducer;
