import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILightQuestion } from './type';

export type QuestionsState = Readonly<{
  questions: ILightQuestion[];
  totalQuestions: number;
}>;

const initialState: QuestionsState = {
  questions: [],
  totalQuestions: 0,
};

const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action: PayloadAction<ILightQuestion[]>) => {
      const draftState = state;
      draftState.questions = action.payload;
    },
    setTotalQuestions: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalQuestions = action.payload;
    },
  },
});

export const { setQuestions, setTotalQuestions } = questionSlice.actions;
export default questionSlice.reducer;
