import ErrorBoundary from 'components/ErrorBoundary';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ErrorPage from 'pages/ErrorPage';
import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router';
import { LanguageEnum } from 'redux/Language';
import { useFetchReferenciels } from 'redux/Referenciel/hooks';
import { useFetchUsefulLinks } from 'redux/UsefulLinks/hooks';
import useSelector from 'redux/useSelector';
import { MainContent, RootContainer } from 'Root.style';
import Routes from 'routes';
import {
  isErreurPage,
  isNotAdminOrChildrenProfil,
  isAdmin,
} from './rootServices';

const Admin = lazy(() => import('./pages/Admin'));
const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));

const Root: React.FC = () => {
  const { pathname } = useLocation();
  const [, doFetchUsefulLinks] = useFetchUsefulLinks();
  const [, doFetchReferenciels] = useFetchReferenciels();

  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    if (!isAdmin(pathname)) doFetchUsefulLinks();
  }, [doFetchUsefulLinks]);

  useEffect(() => {
    if (!isAdmin(pathname)) doFetchReferenciels();
  }, [doFetchReferenciels]);

  const globalClassName = language === LanguageEnum.ar ? 'ar' : 'fr';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense fallback="loading...">
      <RootContainer className={globalClassName}>
        {isAdmin(pathname) ? (
          <div style={{ direction: 'ltr' }}>
            <Admin />
          </div>
        ) : (
          <>
            {isNotAdminOrChildrenProfil(pathname) && <Header />}
            <ErrorBoundary>
              <MainContent>
                <Routes />
              </MainContent>
            </ErrorBoundary>
            {isErreurPage(pathname) && <ErrorPage />}
            {isNotAdminOrChildrenProfil(pathname) && (
              <>
                <Footer />
                <ScrollToTopButton />
              </>
            )}
          </>
        )}
      </RootContainer>
    </Suspense>
  );
};

export default Root;
