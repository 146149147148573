import RichTextRenderer from 'components/RichTextRenderer';
import TitleWithCircle from 'components/TitleWithCircle';
import React from 'react';
import {
  ImgCenteredFlexContainer,
  RelativeContainer,
  StyledParagraph,
  StyledSVGImg,
  TitleContainer,
} from './DescriptionByProfil.style';

export interface DescriptionByProfilProps {
  title: string;
  description: string;
  svgImgUrl: string;
  colorPrimary: string;
  colorSecondary: string;
}

const DescriptionByProfil: React.FC<DescriptionByProfilProps> = ({
  title,
  description,
  svgImgUrl,
  colorPrimary,
  colorSecondary,
}) => {
  return (
    <RelativeContainer>
      <TitleContainer>
        <TitleWithCircle circleColor={colorSecondary} titleColor={colorPrimary}>
          {title}
        </TitleWithCircle>
      </TitleContainer>
      <StyledParagraph>
        <RichTextRenderer html={description} />
      </StyledParagraph>
      <ImgCenteredFlexContainer>
        <StyledSVGImg src={svgImgUrl} alt={title} />
      </ImgCenteredFlexContainer>
    </RelativeContainer>
  );
};

export default DescriptionByProfil;
