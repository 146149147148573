import styled from 'styled-components';

export interface ColumnFlexProps {
  inline?: boolean;
  isAppear?: boolean;
  justify?: 'center' | 'space-between' | 'flex-start';
  align?: 'center' | 'stretch' | 'flex-end' | 'flex-start' | 'inherit';
}

export const ColumnFlex = styled.div<ColumnFlexProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
`;

export default ColumnFlex;
