import { INavProfil } from 'components/ProfilNav/ProfilNav';
import PATHS from 'paths';
import { IntlShape } from 'react-intl';
import { colorPalette } from 'stylesheet';
import {
  ProfilHomePathnameMappingInterface,
  TitleStylePropsByProfilInterface,
} from './types';

export const getBackPageLink = (pathname: string) => {
  switch (pathname) {
    case PATHS.CHILDREN_GUIDES:
      return PATHS.CHILDREN_HOME;
    case PATHS.PARENTS_GUIDES:
      return PATHS.PARENTS_HOME;
    case PATHS.TEACHERS_GUIDES:
      return PATHS.TEACHERS_HOME;
    case PATHS.YOUTH_GUIDES:
      return PATHS.YOUTH_HOME;
    case PATHS.CHILDREN_ADVICES:
      return PATHS.CHILDREN_HOME;
    case PATHS.PARENTS_ADVICES:
      return PATHS.PARENTS_HOME;
    case PATHS.TEACHERS_ADVICES:
      return PATHS.TEACHERS_HOME;
    case PATHS.YOUTH_ADVICES:
      return PATHS.YOUTH_HOME;
    default:
      return '';
  }
};

export const getProfileNavInfos = (
  intl: IntlShape,
  activeProfileIndex: number,
): INavProfil[] => {
  return [
    {
      color: colorPalette.orange,
      name: intl.formatMessage({ id: 'profiles.children' }),
      link: PATHS.CHILDREN_HOME,
      active: activeProfileIndex === 0,
    },
    {
      color: colorPalette.aquamarine,
      name: intl.formatMessage({ id: 'profiles.parents' }),
      link: PATHS.PARENTS_HOME,
      active: activeProfileIndex === 1,
    },
    {
      color: colorPalette.lightGreen,
      name: intl.formatMessage({ id: 'profiles.teachers' }),
      link: PATHS.TEACHERS_HOME,
      active: activeProfileIndex === 2,
    },
    {
      color: colorPalette.falseIndigo,
      name: intl.formatMessage({ id: 'profiles.youth' }),
      link: PATHS.YOUTH_HOME,
      active: activeProfileIndex === 3,
    },
  ];
};

export const PROFIL_HOME_PATHNAME_MAPPING: ProfilHomePathnameMappingInterface = {
  [PATHS.PARENTS_GUIDES]: 'parents',
  [PATHS.PARENTS_HOME]: 'parents',
  [PATHS.PARENTS_ADVICES]: 'parents',
  [PATHS.PARENTS_ADVICE_DETAIL]: 'parents',
  [PATHS.PARENTS_VIDEOTECH]: 'parents',
  [PATHS.TEACHERS_ADVICE_DETAIL]: 'teachers',
  [PATHS.YOUTH_ADVICE_DETAIL]: 'youth',
  [PATHS.TEACHERS_GUIDES_DETAILS]: 'teachers',
  [PATHS.TEACHERS_GUIDES]: 'teachers',
  [PATHS.TEACHERS_HOME]: 'teachers',
  [PATHS.TEACHERS_ADVICES]: 'teachers',
  [PATHS.TEACHERS_VIDEOTECH]: 'teachers',
  [PATHS.YOUTH_GUIDES]: 'youth',
  [PATHS.YOUTH_HOME]: 'youth',
  [PATHS.YOUTH_ADVICES]: 'youth',
  [PATHS.YOUTH_VIDEOTECH]: 'youth',
};

export const TITLE_STYLE_PROPS_BY_PROFIL: TitleStylePropsByProfilInterface = {
  parents: {
    colorPrimary: colorPalette.cyan4,
    colorSecondary: colorPalette.aquamarine,
    colorBackground: colorPalette.falseIndigo,
  },
  teachers: {
    colorPrimary: colorPalette.cyan5,
    colorSecondary: colorPalette.lightGreen,
    colorBackground: colorPalette.cyan5,
  },
  youth: {
    colorPrimary: colorPalette.falseIndigo,
    colorSecondary: colorPalette.aquamarine,
    colorBackground: colorPalette.aquamarine,
  },
};
