import { colorPalette } from 'stylesheet';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface PaginationConfig {
  size?: 'small' | 'large' | 'medium';
  colorButton?: string;
  backgroundColorButton?: string;
}

export const useStyles = makeStyles<Theme, PaginationConfig>({
  root: {
    color: 'red',
    '& ul': {
      justifyContent: 'center',
      gap: 5,
      margin: 20,
    },
    '& .MuiPagination-ul li button': {
      backgroundColor: (props) => props.backgroundColorButton,
      color: (props) => props.colorButton,
      border: (props) => `1px solid ${props.colorButton}`,
      borderRadius: '50%',
    },
    '& .MuiPagination-ul li button.Mui-selected': {
      backgroundColor: `${colorPalette.cyan4}`,
      color: 'white',
      transform: 'scale(1.2)',
      borderRadius: '50%',
    },
    '& .MuiPagination-ul li button.Mui-disabled': {
      display: 'none',
    },
    '& .MuiPagination-ul li:first-child button, .MuiPagination-ul li:last-child button': {
      backgroundColor: `${colorPalette.white}`,
      border: `1px solid ${colorPalette.cyan4}`,
      color: 'white',
      transform: 'scale(1.2)',
      borderRadius: '50%',
    },
    '& .MuiPagination-ul li:first-child button svg, .MuiPagination-ul li:last-child button svg': {
      color: `${colorPalette.cyan4}`,
    },
    'body[dir=rtl] & svg': {
      transform: 'scaleX(-1.1)',
    },
    'body[dir=ltr] & svg': {
      transform: 'scaleX(1.1)',
    },
  },
});
