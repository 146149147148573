import React from 'react';
import { useLocation } from 'react-router';
import {
  PROFIL_HOME_PATHNAME_MAPPING,
  TITLE_STYLE_PROPS_BY_PROFIL,
} from 'pages/services';
import GenericCaroucelSlider from 'components/Generic/GenericCaroucelSlider';
import Popup from 'components/Generic/Popup/Popup';
import { VideoItem } from '../type';

const CarouselSliderWithPopup: React.FC<{ listData: VideoItem[] }> = ({
  listData,
}) => {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState<string>('');

  const handleClickOpen = (url: string) => {
    setSelectedVideo(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { colorPrimary, colorSecondary } =
    TITLE_STYLE_PROPS_BY_PROFIL[PROFIL_HOME_PATHNAME_MAPPING[pathname]] || {};

  return (
    <>
      <GenericCaroucelSlider
        itemsList={listData}
        titleColor={colorPrimary}
        circleColor={colorSecondary}
        showVideo={(url: string) => handleClickOpen(url)}
      />
      <Popup open={open} handleClose={handleClose} videoLink={selectedVideo} />
    </>
  );
};

export default CarouselSliderWithPopup;
