import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ChildGuide {
  title: string;
  subtitle: string;
  label: string;
  fileName: string;
  slug: string;
}
export interface ChildGuidesState {
  childGuides: ChildGuide[];
  selectedChildGuide: ChildGuide;
}

const initialState: ChildGuidesState = {
  childGuides: [],
  selectedChildGuide: {
    title: '',
    subtitle: '',
    label: '',
    fileName: '',
    slug: '',
  },
};

const childGuideSlice = createSlice({
  name: 'childGuides',
  initialState,
  reducers: {
    setChildGuides: (state, action: PayloadAction<ChildGuide[]>) => {
      const draftState = state;
      draftState.childGuides = action.payload;
    },

    setSelectedChildGuide: (state, action: PayloadAction<ChildGuide>) => {
      const draftState = state;
      draftState.selectedChildGuide = action.payload;
    },
  },
});

export const {
  setChildGuides,
  setSelectedChildGuide,
} = childGuideSlice.actions;
export default childGuideSlice.reducer;
