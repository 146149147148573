import { LanguageEnum } from 'redux/Language';

export const getThemeByLang = (globalTheme: any, lang: LanguageEnum) => {
  if (Array.isArray(globalTheme)) {
    return globalTheme.map((element) => getThemeByLang(element, lang));
  }
  if (typeof globalTheme === 'object') {
    if (Object.prototype.hasOwnProperty.call(globalTheme, lang))
      return globalTheme[lang];

    const theme = {};
    Object.keys(globalTheme).forEach((key) => {
      theme[key] = getThemeByLang(globalTheme[key], lang);
    });

    return theme;
  }

  return globalTheme;
};
