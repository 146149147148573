import PATHS from 'paths';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ErrorBoundary as CustomErrorBoundary } from 'react-error-boundary';

const ErrorFallback = () => {
  return <Redirect to={PATHS.ERREUR} />;
};

const ErrorBoundary: React.FC = ({ children }) => (
  <CustomErrorBoundary FallbackComponent={ErrorFallback}>
    {children}
  </CustomErrorBoundary>
);

export default ErrorBoundary;
