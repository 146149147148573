import PATHS from './paths';

const isChildrenProfil = (pathname: string): boolean => {
  return !!pathname.match('/enfants/.+');
};

export const isAdmin = (pathname: string): boolean => {
  return !!pathname.match('^/admin($|#/.*)');
};

export const isErreurPage = (pathname: string): boolean => {
  return pathname === PATHS.ERREUR;
};

export const isNotAdminOrChildrenProfil = (pathname: string): boolean =>
  !isAdmin(pathname) && !isChildrenProfil(pathname);
