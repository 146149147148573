import React from 'react';
import { useHistory } from 'react-router';
import Error404Img from 'assets/svg/Error404.svg';
import { FormattedMessage } from 'react-intl';
import Background from 'components/Background';
import AquamarineButton from 'components/Button/AquamarineButton';
import {
  ErrorPageContainer,
  StyledImg,
  StyledNotFoundText,
} from './ErrorPage.style';

const ErrorPage: React.FC = () => {
  const history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <Background isPublicHome>
      <ErrorPageContainer>
        <StyledImg src={Error404Img} alt="Error 404" />
        <StyledNotFoundText>
          <FormattedMessage id="error-404.not-found-text" />
        </StyledNotFoundText>
        <AquamarineButton onClick={goToPreviousPath}>
          <FormattedMessage id="error-404.back-button" />
        </AquamarineButton>
      </ErrorPageContainer>
    </Background>
  );
};

export default ErrorPage;
