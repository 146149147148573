import React from 'react';
import { useSelector } from 'react-redux';
import {
  BackgroundContainer,
  PositionnedCirclesGroup,
  StyledBackgroundLeftBigCircleSvg,
  StyledBackgroundRightBigCircleSvg,
  StyledCubicFourDots,
} from './Background.style';

interface BackgroundProps {
  isPublicHome?: boolean;
}

const Background: React.FC<BackgroundProps> = ({ children, isPublicHome }) => {
  const { language } = useSelector((state) => state.language);
  return (
    <BackgroundContainer data-testid="bg-container">
      <StyledBackgroundLeftBigCircleSvg lang={language} />
      <StyledBackgroundRightBigCircleSvg lang={language} />
      {isPublicHome ? (
        <PositionnedCirclesGroup data-testid="circles-grp-svg" />
      ) : (
        <StyledCubicFourDots />
      )}
      {children}
    </BackgroundContainer>
  );
};

export default Background;
