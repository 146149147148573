import RowFlex from 'components/RowFlex';
import styled from 'styled-components';
import { TABLET_MAX_WIDTH } from 'stylesheet';

export const ProfilNavMain = styled(RowFlex)`
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    flex-wrap: wrap;
    align-content: space-between;
    gap: 20px;
  }
`;
