import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import * as React from 'react';
import ReactPlayer from 'react-player';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './Popup.style';

interface FormsAction {
  handleClose: () => void;
  open: boolean;
  videoLink: string;
}

const Popup: React.FC<FormsAction> = ({ handleClose, open, videoLink }) => {
  const classes = useStyles();
  return (
    <Dialog
      data-testid="add-group-dialog"
      className={classes.dialog_wrap}
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{
        style: {
          overflow: 'inherit',
        },
      }}
    >
      <IconButton
        data-testid="add-group"
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent className={classes.dialog_content}>
        <ReactPlayer
          controls
          className={classes.react_player}
          playing
          loop
          url={videoLink}
        />
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
