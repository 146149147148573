import { GridSize, Box, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react';
import { colorPalette } from 'stylesheet';
import { PaginationConfig, useStyles } from './ListTileData.style';

interface ListTileDataProps {
  data: any[];
  onMoreDetail?: (id: string) => void;
  tuile: React.ReactElement;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  rowPerPage?: number;
  sizePagnigation?: 'small' | 'large' | 'medium';
  paginationConfig?: PaginationConfig;
}

const paginationConfigInit: PaginationConfig = {
  size: 'medium',
  colorButton: colorPalette.white,
  backgroundColorButton: colorPalette.aquamarine,
};

const ListTileData: React.FC<ListTileDataProps> = ({
  data,
  onMoreDetail,
  tuile,
  xs = 12,
  sm = 6,
  md = 4,
  lg = 4,
  rowPerPage = 6,
  paginationConfig = paginationConfigInit,
}) => {
  const [currentPage, setPage] = useState<number>(0);
  const classes = useStyles({ ...paginationConfigInit, ...paginationConfig });
  return (
    <Box margin={3}>
      <Grid container spacing={2} justifyContent="center">
        {data
          .slice(
            currentPage * rowPerPage,
            currentPage * rowPerPage + rowPerPage,
          )
          .map((next) => {
            return (
              <Grid container item xs={xs} sm={sm} md={md} lg={lg} spacing={2}>
                {React.cloneElement(tuile, {
                  data: { ...next },
                  onMoreDetail,
                  id: next.id,
                })}
              </Grid>
            );
          })}
      </Grid>
      <Box>
        <Pagination
          className={classes.root}
          size={paginationConfig.size}
          count={Math.ceil(data.length / rowPerPage)}
          onChange={(_, page) => {
            setPage(page - 1);
          }}
        />
      </Box>
    </Box>
  );
};

export default ListTileData;
