import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParentalControlDetails, IParentalControl } from './type';

export type ParentalControlState = Readonly<{
  parentalControls: IParentalControl[];
  selectedParentalControlDetails: IParentalControlDetails;
  totalParentalControls: number;
}>;

const initialState: ParentalControlState = {
  parentalControls: [],
  selectedParentalControlDetails: {
    id: 0,
    title: '',
    items: [],
  },
  totalParentalControls: 0,
};

const parentalControlSlice = createSlice({
  name: 'parentalControls',
  initialState,
  reducers: {
    setParentalControls: (state, action: PayloadAction<IParentalControl[]>) => {
      const draftState = state;
      draftState.parentalControls = action.payload;
    },
    setSelectedParentalControlDetails: (
      state,
      action: PayloadAction<IParentalControlDetails>,
    ) => {
      const draftState = state;
      draftState.selectedParentalControlDetails = action.payload;
    },
    setTotalParentalControls: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalParentalControls = action.payload;
    },
  },
});

export const {
  setParentalControls,
  setSelectedParentalControlDetails,
  setTotalParentalControls,
} = parentalControlSlice.actions;
export default parentalControlSlice.reducer;
