import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPartner {
  name: string;
  fileName: string;
}

export interface PartnersState {
  activePartners: IPartner[];
}

const initialState: PartnersState = {
  activePartners: [],
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setActivePartners: (_state, action: PayloadAction<IPartner[]>) => ({
      activePartners: action.payload,
    }),
  },
});

export const { setActivePartners } = partnersSlice.actions;
export default partnersSlice.reducer;
