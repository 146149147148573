import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import flattenMessages from 'services/i18n/intl';
import frMessages from 'translations/fr.json';
import arMessages from 'translations/ar.json';
import useSelector from 'redux/useSelector';
import { LanguageEnum } from 'redux/Language';

export const locales = {
  fr: flattenMessages(frMessages),
  ar: flattenMessages(arMessages),
};

export const TranslationsProvider: React.FC = ({ children }) => {
  const { language } = useSelector((state) => state.language);
  const getWritingDirection = () => {
    switch (language) {
      case LanguageEnum.ar:
        return 'rtl';
      default:
        return 'ltr';
    }
  };

  useEffect(() => {
    document.body.setAttribute('dir', getWritingDirection());
  }, [language]);

  return (
    <IntlProvider
      locale={language}
      messages={locales[language]}
      defaultLocale="fr"
    >
      {children}
    </IntlProvider>
  );
};
