import styled from 'styled-components';
import { colorPalette, FontType, typography } from 'stylesheet';
import { LanguageEnum } from 'redux/Language';

interface Props {
  fontType?: FontType;
  textAlign?: 'justify' | 'start' | 'center';
  lang?: LanguageEnum.ar | LanguageEnum.fr;
}

const Paragraph = styled.p<Props>`
  ${(props) => typography[props.fontType || 'small']};
  color: ${colorPalette.outerSpace};
  text-align: ${(props) => props.textAlign || 'justify'};
`;

export default Paragraph;
