import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILightQuizDTO } from './type';

export type QuizzesState = Readonly<{
  quizzes: ILightQuizDTO[];
  selectedQuiz: ILightQuizDTO | undefined;
  totalQuizzes: number;
}>;

const initialState: QuizzesState = {
  quizzes: [],
  selectedQuiz: undefined,
  totalQuizzes: 0,
};

const quizSlice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    setQuizzes: (state, action: PayloadAction<ILightQuizDTO[]>) => {
      const draftState = state;
      draftState.quizzes = action.payload;
    },
    setSelectedQuiz: (state, action: PayloadAction<ILightQuizDTO>) => {
      const draftState = state;
      draftState.selectedQuiz = action.payload;
    },
    setTotalQuizzes: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalQuizzes = action.payload;
    },
  },
});

export const {
  setQuizzes,
  setTotalQuizzes,
  setSelectedQuiz,
} = quizSlice.actions;
export default quizSlice.reducer;
