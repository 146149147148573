import React from 'react';
import ProfilLinkButton from './ProfilLinkButton';
import { ProfilNavMain } from './ProfilNav.style';

export interface INavProfil {
  name: string;
  color: string;
  link: string;
  active?: boolean;
}

export interface ProfilNavProps {
  profiles: INavProfil[];
}

const ProfilNav: React.FC<ProfilNavProps> = ({ profiles }) => {
  return (
    <ProfilNavMain>
      {profiles.map(({ name, color, link, active }) => (
        <ProfilLinkButton
          key={name}
          to={link}
          className={active ? 'active' : ''}
          color={color}
        >
          {name}
        </ProfilLinkButton>
      ))}
    </ProfilNavMain>
  );
};

export default ProfilNav;
