import styled from 'styled-components';
import { typography } from 'stylesheet';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';

export const StyledDialog = styled(Dialog)`
  max-width: 600px;
  width: 100%;
  position: relative;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0 !important;
  overflow: hidden;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  width: 100%;
  height: 360px;
`;

export const Title = styled.span`
  display: block;
  line-height: normal;
  padding: 7px 0;
  ${typography.small}
`;

export const useStyles = makeStyles({
  dialog_wrap: {},
  dialog_content: {
    overflow: 'hidden',
    aspectRatio: '16 / 9',
    padding: '10px !important',
  },
  react_player: {
    width: '100% !important',
    height: '100% !important',
  },
  closeButton: {
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    color: '#9e9e9e',
    background: 'white',
    border: '3px solid #000',
    padding: '6px',
    fontSize: '3rem',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
});
