import styled from 'styled-components';
import { colorPalette, calcViewWidthPort } from 'stylesheet';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${colorPalette.aquamarine};
  border-radius: 50%;
  border: none;
  width: ${calcViewWidthPort(74)};
  height: ${calcViewWidthPort(74)};
  position: fixed;
  bottom: ${calcViewWidthPort(75)};
  inset-inline-end: ${calcViewWidthPort(40)};
`;

export default StyledButton;
