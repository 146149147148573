import ColumnFlex from 'components/ColumnFlex';
import styled from 'styled-components';

export const RootContainer = styled(ColumnFlex)`
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
  position: relative;
`;

export const MainContent = styled.div`
  flex: 1;
`;
