import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import HomeByProfil from 'pages/HomeByProfil';
import { REACT_APP_ENV } from 'services/config';
import PATHS from './paths';

const Home = lazy(() => import('./pages/Home'));
const Guides = lazy(() => import('./pages/Guides'));
const GuidesDetails = lazy(() => import('./pages/GuidesDetail'));
const ChildHome = lazy(() => import('./pages/ChildHome'));
const Initiatives = lazy(() => import('./pages/Initiatives'));
const Actualities = lazy(() => import('./pages/Actualities'));
const ActualityDetail = lazy(() => import('./pages/ActualityDetail'));
const LegalFramework = lazy(() => import('./pages/LegalFramework'));
const LegalNotice = lazy(() => import('./pages/LegalNotice'));
const UseConditions = lazy(() => import('./pages/UseConditions'));
const CodePenal = lazy(() => import('./pages/CodePenal'));
const UtilInfos = lazy(() => import('./pages/UtilInfos'));
const ChildMenu = lazy(() => import('./pages/ChildMenu'));
const ChildQuizMenuIntro = lazy(() => import('./pages/ChildQuizMenuIntro'));
const ChildQuizMenu = lazy(() => import('./pages/ChildQuizMenu'));
const ChildQuiz = lazy(() => import('./pages/ChildQuiz'));
const InitiativeDetail = lazy(() => import('./pages/InitiativeDetail'));
const ChildLibraryRouter = lazy(() => import('./pages/ChildLibrary'));
const Glossary = lazy(() => import('./pages/Glossary'));
const Advices = lazy(() => import('./pages/Advices'));
const AdviceDetails = lazy(() => import('./pages/AdviceDetails'));
const ParentalControl = lazy(() => import('./pages/ParentalControl'));
const ChildIntro = lazy(() => import('./pages/ChildVideo'));
const ControlParentalDetail = lazy(
  () => import('./pages/ParentalControlElementDetail'),
);
const RichTextEditorForDev = lazy(() => import('./pages/RichTextEditorForDev'));

const Routes: React.FC = () => (
  <Switch>
    <Route exact path={PATHS.HOME} component={Home} />
    <Route exact path={PATHS.CHILDREN_HOME} component={ChildHome} />
    <Route exact path={PATHS.CHILDREN_MENU} component={ChildMenu} />
    <Route exact path={PATHS.CHILDREN_VIDEO_INTRODUCTION}>
      <ChildIntro isIntro />
    </Route>
    <Route path={PATHS.CHILDREN_LIBRARY} component={ChildLibraryRouter} />
    <Route
      exact
      path={PATHS.CHILDREN_QUIZ_MENU_INTRO}
      component={ChildQuizMenuIntro}
    />
    <Route exact path={PATHS.CHILDREN_QUIZ_MENU} component={ChildQuizMenu} />
    <Route exact path={PATHS.CHILDREN_QUIZ} component={ChildQuiz} />
    <Route
      exact
      path={[
        PATHS.HOME_BY_PROFIL,
        PATHS.PARENTS_HOME,
        PATHS.TEACHERS_HOME,
        PATHS.YOUTH_HOME,
      ]}
      component={HomeByProfil}
    />
    <Route
      exact
      path={[
        PATHS.PARENTS_VIDEOTECH,
        PATHS.YOUTH_VIDEOTECH,
        PATHS.TEACHERS_VIDEOTECH,
      ]}
    >
      <HomeByProfil isVideotech />
    </Route>
    <Route exact path={PATHS.CHILDREN_VIDEOTECH} component={ChildIntro} />
    <Route
      exact
      path={[
        PATHS.GUIDES,
        PATHS.PARENTS_GUIDES,
        PATHS.TEACHERS_GUIDES,
        PATHS.YOUTH_GUIDES,
      ]}
      component={Guides}
    />
    <Route
      exact
      path={[
        PATHS.PARENTS_GUIDES_DETAILS,
        PATHS.TEACHERS_GUIDES_DETAILS,
        PATHS.YOUTH_GUIDES_DETAILS,
      ]}
      component={GuidesDetails}
    />

    <Route exact path={PATHS.INITIATIVES} component={Initiatives} />
    <Route exact path={PATHS.ACTUALITIES} component={Actualities} />
    <Route exact path={PATHS.ACTUALITIES_DETAIL} component={ActualityDetail} />
    <Route
      exact
      path={PATHS.PARENTS_PARENTAL_CONTROL}
      component={ParentalControl}
    />
    <Route exact path={PATHS.GLOSSARY} component={Glossary} />
    <Route path={PATHS.LEGAL_FRAMEWORK} component={LegalFramework} />
    <Route exact path={PATHS.LEGAL_NOTICE} component={LegalNotice} />
    <Route exact path={PATHS.USE_CONDITIONS} component={UseConditions} />
    <Route path={PATHS.CODE_PENAL} component={CodePenal} />
    <Route exact path={PATHS.INFORMATIONS_UTILES} component={UtilInfos} />
    <Route path={PATHS.INITIATIVE_DETAIL} component={InitiativeDetail} />
    <Route
      exact
      path={[
        PATHS.PARENTS_ADVICES,
        PATHS.TEACHERS_ADVICES,
        PATHS.YOUTH_ADVICES,
      ]}
      component={Advices}
    />
    <Route
      exact
      path={[
        PATHS.PARENTS_ADVICE_DETAIL,
        PATHS.TEACHERS_ADVICE_DETAIL,
        PATHS.YOUTH_ADVICE_DETAIL,
      ]}
      component={AdviceDetails}
    />
    <Route
      path={PATHS.PARENTS_PARENTAL_CONTROL_DETAILS}
      component={ControlParentalDetail}
    />
    {REACT_APP_ENV !== 'production' && (
      <Route exact path={PATHS.RICHTEXT_IO} component={RichTextEditorForDev} />
    )}
    <Redirect to={PATHS.ERREUR} />
  </Switch>
);

export default Routes;
