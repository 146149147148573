import PATHS from 'paths';

export const PathsMap = new Map<string, string>([
  [PATHS.PARENTS_HOME, PATHS.PARENTS_VIDEOTECH],
  [PATHS.TEACHERS_HOME, PATHS.TEACHERS_VIDEOTECH],
  [PATHS.YOUTH_HOME, PATHS.YOUTH_VIDEOTECH],
]);

export interface PersonnaMapType {
  personna: string;
  filter: string;
}

export const PersonaMap = new Map<string, PersonnaMapType>([
  [PATHS.PARENTS_HOME, { personna: 'PARENTS', filter: 'slided' }],
  [PATHS.TEACHERS_HOME, { personna: 'TEACHERS', filter: 'slided' }],
  [PATHS.YOUTH_HOME, { personna: 'YOUTH', filter: 'slided' }],
  [PATHS.PARENTS_VIDEOTECH, { personna: 'PARENTS', filter: 'slided' }],
  [PATHS.TEACHERS_VIDEOTECH, { personna: 'TEACHERS', filter: 'slided' }],
  [PATHS.YOUTH_VIDEOTECH, { personna: 'YOUTH', filter: 'slided' }],
  [PATHS.CHILDREN_VIDEOTECH, { personna: 'CHILDREN', filter: 'slided' }],
  [
    PATHS.CHILDREN_VIDEO_INTRODUCTION,
    { personna: 'CHILDREN', filter: 'slided' },
  ],
]);
