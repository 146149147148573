/* eslint-disable complexity */
import styled from 'styled-components';
import {
  calcViewWidthPort,
  colorPalette,
  MOBILE_MAX_WIDTH,
  reactAdminRichTextStyle,
  TABLET_MAX_WIDTH,
  typography,
} from 'stylesheet';
import RowFlex from 'components/RowFlex';
import ColumnFlex from 'components/ColumnFlex';
import { LanguageEnum } from 'redux/Language';

export interface SingleTabInterface {
  title: string;
  colorPrimary: string;
  colorSecondary: string;
  imgUrl: string;
  text: string;
  buttonLabel: string;
  link: string;
  isDynamicFromApi?: boolean;
  staticContentKey?: string;
}

export const TabsContainer = styled(ColumnFlex)`
  width: 100%;
  align-items: stretch;
`;

export const NavTabs = styled(RowFlex)`
  margin-bottom: ${calcViewWidthPort(35)};
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-wrap: nowrap;
  }
`;

export const TabBody = styled(RowFlex)`
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: column;
  }
`;

interface TabElementProps {
  primaryColor: string;
  secondaryColor: string;
  index: number;
  max: number;
  lang: string;
  active?: boolean;
}

const getTabElementBorderRadius = (
  isFirst: boolean,
  isLast: boolean,
  lang: string,
): string => {
  if (isFirst) {
    if (lang === LanguageEnum.fr)
      return `${calcViewWidthPort(30)} 0 0 ${calcViewWidthPort(30)}`;
    return `0 ${calcViewWidthPort(30)} ${calcViewWidthPort(30)} 0`;
  }
  if (isLast) {
    if (lang === LanguageEnum.fr)
      return `0 ${calcViewWidthPort(30)} ${calcViewWidthPort(30)} 0`;
    return `${calcViewWidthPort(30)} 0 0 ${calcViewWidthPort(30)}`;
  }
  return '';
};

export const TabElement = styled(RowFlex)<TabElementProps>`
  ${typography.small};
  width: ${calcViewWidthPort(140)};
  height: ${calcViewWidthPort(60)};
  color: ${colorPalette.white};
  border-radius: ${(props) =>
    getTabElementBorderRadius(
      props.index === 0,
      props.index === props.max - 1,
      props.lang,
    )};
  background-color: ${(props) =>
    props.active ? props.primaryColor : props.secondaryColor};
  cursor: pointer;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    ${typography.smallTablet};
    width: 160px;
    height: 40px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: auto;
    padding: 0 15px;
    text-align: center;
    line-height: 16px;
  }
`;

export const TabImg = styled.img`
  width: ${calcViewWidthPort(268)};
  height: ${calcViewWidthPort(244)};
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 0.5rem auto 1rem auto;
  }
`;

export const TabMain = styled(ColumnFlex)`
  margin-inline-start: ${calcViewWidthPort(30)};
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    align-items: center;
  }
`;

interface TabButtonProps {
  backgroundColor: string;
}
export const TabButton = styled(RowFlex)<TabButtonProps>`
  ${typography.small};
  background-color: ${(props) => props.backgroundColor};
  width: ${calcViewWidthPort(175)};
  height: ${calcViewWidthPort(49)};
  border-radius: ${calcViewWidthPort(30)};
  color: ${colorPalette.white};
  margin-top: ${calcViewWidthPort(30)};
  cursor: pointer;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    ${typography.smallTablet};
    width: 170px;
    height: 40px;
  }
`;
export const TabText = styled.div`
  ${typography.medium};
  font-weight: 400;
  white-space: pre-line;
  min-height: 200px;
`;

export const RichTextRendererContainer = styled.div`
  ${reactAdminRichTextStyle};
  width: 100%;
  white-space: pre-wrap;
  direction: ${(props) => props.theme.richTextRenderer.direction};
  line-height: ${(props) => props.theme.richTextRenderer.lineHeight};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
  }
`;
