import styled from 'styled-components';
import {
  colorPalette,
  calcViewWidthPort,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from 'stylesheet';
import { ReactComponent as Ellipse } from 'assets/svg/Ellipse.svg';
import { ReactComponent as HorizontalDots } from 'assets/svg/HorizontalDots.svg';
import { ReactComponent as VerticalDots } from 'assets/svg/VerticalDots.svg';
import Carousel from 'components/Carousel';
import ColumnFlex from 'components/ColumnFlex';

export const CarouselSliderContainer = styled.div`
  width: 100%;
`;

export const CarouselSliderBackground = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.aquamarine};
  opacity: 0.2;
  width: 100%;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    position: relative;
    min-width: 450px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    position: relative;
    min-width: 350px;
  }
`;

export const CarouselSliderMain = styled(ColumnFlex)`
  position: relative;
  background-color: ${colorPalette.lightCyan};
  padding-bottom: 40px;
  z-index: 1;
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    margin: 0 5px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    margin: 0 5px;
  }
  @media screen and (max-width: 320px) {
    margin: 0 5px;
  }
`;

export const CostumedSlider = styled(Carousel)`
  display: flex;
  position: relative;
  width: 100%;
  top: ${calcViewWidthPort(300)};
`;

export const SliderSpacing = styled.div`
  height: ${calcViewWidthPort(50)};
  @media screen and (max-width: ${TABLET_MAX_WIDTH}) {
    height: 10px;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
    height: 5px;
  }
`;
export const EllipseImg = styled(Ellipse)`
  position: relative;
  width: ${calcViewWidthPort(112)};
  height: ${calcViewWidthPort(112)};
  top: ${calcViewWidthPort(90)};
  inset-inline-end: ${calcViewWidthPort(115)};
`;

export const HorizontalDotsContainer = styled(HorizontalDots)`
  position: relative;
  width: ${calcViewWidthPort(340)};
  height: ${calcViewWidthPort(100)};
  bottom: ${calcViewWidthPort(150)};
  inset-inline-start: ${calcViewWidthPort(441)};
`;

export const VerticalDotsContainer = styled(VerticalDots)`
  position: absolute;
  width: ${calcViewWidthPort(260)};
  height: ${calcViewWidthPort(140)};
  top: ${calcViewWidthPort(67)};
  inset-inline-start: ${calcViewWidthPort(129)};
`;
