import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UsefulLinkI {
  label: string;
  link: string;
}

export interface UsefulLinksState {
  usefulLinks: UsefulLinkI[];
}

const initialState: UsefulLinksState = {
  usefulLinks: [],
};

const usefulLinksSlice = createSlice({
  name: 'usefulLinks',
  initialState,
  reducers: {
    setUsefulLinks: (state, action: PayloadAction<UsefulLinkI[]>) => ({
      usefulLinks: action.payload,
    }),
  },
});

export const { setUsefulLinks } = usefulLinksSlice.actions;
export default usefulLinksSlice.reducer;
