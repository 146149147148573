import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Tag {
  name: string;
}
interface Actuality {
  title: string;
  imageName: string;
  slug: string;
}

export interface HomeActuality extends Actuality {
  miniSummary: string;
}
export interface SummaryActuality extends Actuality {
  summary: string;
  tags: Tag[];
}
export interface SelectedActuality extends Actuality {
  details: string;
}

export interface ActualitiesState {
  homeActualities: HomeActuality[];
  actualities: SummaryActuality[];
  selectedActuality: SelectedActuality;
  totalActualities: number;
}

const initialState: ActualitiesState = {
  actualities: [],
  homeActualities: [],
  selectedActuality: {
    title: '',
    details: '',
    slug: '',
    imageName: '',
  },
  totalActualities: 0,
};

const actualitySlice = createSlice({
  name: 'actualities',
  initialState,
  reducers: {
    setHomeActualities: (state, action: PayloadAction<HomeActuality[]>) => {
      const draftState = state;
      draftState.homeActualities = action.payload;
    },
    setActualities: (state, action: PayloadAction<SummaryActuality[]>) => {
      const draftState = state;
      draftState.actualities = action.payload;
    },
    setSelectedActuality: (state, action: PayloadAction<SelectedActuality>) => {
      const draftState = state;
      draftState.selectedActuality = action.payload;
    },
    setTotalActualities: (state, action: PayloadAction<number>) => {
      const draftState = state;
      draftState.totalActualities = action.payload;
    },
  },
});

export const {
  setHomeActualities,
  setActualities,
  setSelectedActuality,
  setTotalActualities,
} = actualitySlice.actions;
export default actualitySlice.reducer;
